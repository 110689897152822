import { inject, Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Pipe({
    name: "safeUrl",
})
export class SafeUrlPipe implements PipeTransform {
    private sanitized = inject(DomSanitizer);

    transform(value: string): SafeResourceUrl {
        return this.sanitized.bypassSecurityTrustResourceUrl(value);
    }
}
