import { AbstractControl, UntypedFormGroup } from "@angular/forms";
import _cloneDeep from "lodash-es/cloneDeep";

import { Countries } from "../constants/countries";
import { DuplicateAction } from "../constants/duplicate-actions";
import { LL_FIELD_UNIQUE_IDENTIFIER } from "../constants/transcription-fields";
import {
    BaseItemData,
    ElementId,
    ElementOrSubElementId,
    ElementsTypeMap,
    FormElementType,
    SubElementId,
    SubElementMapping,
} from "../model";
import { Severity } from "../model/SentryTypes";
import { AnalyticsService } from "../services/analytics.service";
import { isArrayOfNumbers, isArrayOfString, isBusinessCardObject } from "../util/typeAssert";
import { Util } from "../util/util";

import { Activation } from "./activation";
import { UserWithAccessToForm } from "./admin/UserWithAccessToForm";
import { BaseForm } from "./base-form";
import { EventStyle } from "./event-style";
import { FORM_ELEMENT_MEDIA, FormElement } from "./form-element";
import { ActionModes, FormFillAction } from "./form-fill-actions";
import { FormGoals } from "./form-goals";
import { WebviewSuccessActionsTypes } from "./mobile-success-actions";
import { SubmissionQuestionData } from "./question-feeder";
import { RatingColors } from "./rating-colors";
import { Station } from "./station";
import { SubmissionAction, SubmissionActionMap, SubmissionActionTypes } from "./submission-action";
import { ISubmissionUser } from "./submission-user";
import { Theme } from "./theme";

export type EventDate = {
    from: string;
    to: string;
};

export type EventType = {
    id: number;
    name: string;
    alias: string;
    is_used_in_ulc_app: boolean;
    is_accept_ulc_submission: boolean;
};
export type Session = {
    id: number;
    identifier: string;
    title: string;
    description: string;
    location: string | null;
    start_date: string;
    end_date: string;
};

export class Form extends BaseForm {
    created_at: string = "";
    updated_at: string = "";
    archive_date: string = "";
    list_id: number = 0;
    title: string = "";
    success_message: string = "";
    submit_error_message: string = "";
    submit_button_text: string = "";
    is_mobile_kiosk_mode: boolean = false;
    is_mobile_quick_capture_mode: boolean = false;
    elements: FormElement[] = [];
    instructions_content: string = "";
    // Display on initial open option in webapp
    is_enforce_instructions_initially: boolean = false;
    event_stations: Station[] = [];
    is_enable_rapid_scan_mode: boolean = false;
    event_style: EventStyle = {} as EventStyle;
    available_for_users: ISubmissionUser[] = [];
    activations: Activation[] = [];
    show_reject_prompt: boolean = false;
    duplicate_action: DuplicateAction = DuplicateAction.None;
    unique_identifier_barcode: boolean = false;
    unique_identifier_name: boolean = false;
    unique_identifier_email: boolean = false;
    ignore_submissions_from_activations: boolean = false;
    is_show_search_preview_results: boolean = false;
    showStaffOnMobileSide: boolean = false;
    // mobile_success_action: MobileSuccessActions = { type: MobileSuccessActionsTypes.StartOver };
    submission_actions: SubmissionAction[] = [];
    event_date: EventDate = {} as EventDate;
    is_enable_webview_max_width: boolean = true;
    members_last_sync_date: string = "";
    is_instructions_webview: boolean = false;
    search_list_background_color: string = "";
    search_list_text_color: string = "";
    webview_success_action_type: WebviewSuccessActionsTypes = WebviewSuccessActionsTypes.StartOver;
    webview_success_message: string = "";
    webview_success_redirect_url: string = "";
    prospect_info_tracking_url: string = "";
    is_enable_webview_location: boolean = false;
    assign_owner: UserWithAccessToForm;
    goals: FormGoals = { criteria: [] };
    event_type: EventType = {} as EventType;
    session?: Session = {} as Session;
    form_fill_actions: FormFillAction[] = [];
    is_enabled_email_verification: boolean = false;
    enables_influenced_pipeline: boolean = false;

    public static getInstance(data: any): Form {
        const form = new Form();
        if (data) {
            Object.keys(form).forEach((key) => {
                form[key] = data[key] || form[key];
            });
            form.sortEls();
            form.hideIntelliScan();
        }
        return form;
    }

    hideIntelliScan() {
        this.elements = this.elements.filter(
            (el) =>
                el.type !== FormElementType.barcode ||
                (el.type === FormElementType.barcode && !el.is_intelliscan_enabled),
        );
    }

    public getIdByElementTitle(name: string): string | undefined {
        return this.elements.find((element) => element.title.toLowerCase() == name.toLowerCase())?.id;
    }

    public getIdsByElementType(type: FormElementType): string[] {
        return this.elements.filter((element) => element.type === type).map((element) => element.id);
    }

    public getElementByType<T extends FormElementType>(type: T): ElementsTypeMap[T] | undefined {
        return this.elements.find((element) => element.type === type) as ElementsTypeMap[T] | undefined;
    }

    public getElementsByType<T extends FormElementType>(type: T): ElementsTypeMap[T][] {
        return this.elements.filter((element) => element.type === type) as ElementsTypeMap[T][];
    }

    public static fillFormGroupDataWithNearestOption(
        data: { id: ElementOrSubElementId; type: FormElementType; value: unknown }[],
        formGroup: UntypedFormGroup,
        form: Form,
    ): void {
        data = Form.replaceValuesWithNearestOption(data, form);
        this.fillFormGroupData(data, formGroup);
    }

    public static fillFormGroupData(
        data: { id: ElementOrSubElementId; type: FormElementType; value: unknown }[],
        formGroup: UntypedFormGroup,
    ): void {
        let filledAtLeastOnce = false;
        const values = {};
        const controls = formGroup.controls;
        for (const id in controls) {
            if (controls[id]["controls"]) {
                values[id] = {};
                for (const subId in controls[id]["controls"]) {
                    values[id][subId] = controls[id]["controls"][subId].value;
                }
            } else {
                values[id] = controls[id].value;
            }
        }

        data.forEach((entry) => {
            const id = entry.id;
            if (!id) {
                return;
            }

            const isSubElement = Util.isSubElementId(id);
            let ctrl: AbstractControl | null | undefined;

            if (isSubElement) {
                const parentElement = Util.getParentId(id);
                if (!values[parentElement]) {
                    values[parentElement] = {};
                }
                ctrl = formGroup.get(parentElement)?.get(id);
            } else {
                ctrl = formGroup.get(id);
            }
            if (ctrl && Form.assertValueToFillWith(entry)) {
                filledAtLeastOnce = true;
                ctrl.setValue(entry.value);
                ctrl.markAsTouched();
                ctrl.markAsDirty();
                ctrl.updateValueAndValidity();
            }
            if (!filledAtLeastOnce) {
                AnalyticsService.captureMessage({ message: "Data did not fill the form", level: Severity.Warning });
            }
        });
    }

    private static assertValueToFillWith(data: { type: FormElementType; value: unknown }): boolean {
        const { type, value } = data;
        switch (type) {
            case FormElementType.boolean:
                return typeof value === "boolean";
            case FormElementType.audio:
                return typeof value === "string" && value.startsWith("http");
            case FormElementType.checkbox:
            case FormElementType.image:
                return isArrayOfString(value);
            case FormElementType.document:
                return isArrayOfNumbers(value);
            case FormElementType.business_card:
                return isBusinessCardObject(value);
            default:
                return typeof value === "string";
        }
    }

    public getSubmissionDataArrayFromItemsData(
        data: BaseItemData[],
    ): { id: ElementOrSubElementId; type: FormElementType; value: unknown }[] {
        const pairs: { id: ElementOrSubElementId; type: FormElementType; value: unknown }[] = [];
        for (const entry of data) {
            const id = getIdByUniqueElementName(entry.ll_field_unique_identifier, this.elements);
            if (id) {
                let value: string | string[] | SubmissionQuestionData[] = entry.value;
                if (entry.ll_field_unique_identifier === "Country") {
                    for (const country of Countries) {
                        if (country.name.toLowerCase() === entry.value.toLowerCase()) {
                            break;
                        }
                        if (country.aliases?.includes(entry.value.toUpperCase())) {
                            value = country.name;
                            break;
                        }
                    }
                }

                const parentElementId = Util.getParentId(id);
                const unknownElement = this.getElementById(parentElementId);

                if (!unknownElement) continue;

                // single line text fields should only have 255 characters only
                if (unknownElement.type === FormElementType.text && typeof value === "string") {
                    value = value.substring(0, 255);
                }

                //image variants data return from tracking engine as string which should be manipulated to string[]
                if (unknownElement?.type === FormElementType.image) {
                    if (unknownElement.variant) {
                        value = [value];
                    } else {
                        value = JSON.parse(value);
                    }
                }

                pairs.push({ id, type: unknownElement.type, value });
            }
        }
        return pairs;
    }

    public fillElementsWithFetchedData(data: BaseItemData[], formGroup: UntypedFormGroup): void {
        let pairs = this.getSubmissionDataArrayFromItemsData(data);

        pairs = pairs.filter((pair) => pair.value);

        Form.fillFormGroupDataWithNearestOption(pairs, formGroup, this);
    }

    private static replaceValuesWithNearestOption(
        pairs: { id: ElementOrSubElementId; type: FormElementType; value: unknown }[],
        form: Form,
    ): { id: ElementOrSubElementId; type: FormElementType; value: unknown }[] {
        return pairs.map((pair) => {
            // filteredElement could be undefined if the id is for a subElement
            const filteredElement = form.getElementById(pair.id);
            if (filteredElement?.type === FormElementType.radio || filteredElement?.type === FormElementType.select) {
                const value = pair.value;
                if (typeof value === "string") {
                    const matchingOption = filteredElement.options.find(
                        (option) => option.option.toLowerCase().trim() === value.toString().toLowerCase().trim(),
                    );
                    if (matchingOption) {
                        return {
                            ...pair,
                            value: matchingOption.option,
                        };
                    }
                }
            }
            if (filteredElement?.type === FormElementType.checkbox) {
                const OptionsToSelect: string[] = [];
                if (Array.isArray(pair.value)) {
                    for (const val of pair.value) {
                        const matchingOption = filteredElement.options.find(
                            (option) => val.toLowerCase().trim() === option.option.toLowerCase().trim(),
                        );
                        if (matchingOption) {
                            OptionsToSelect.push(matchingOption.option);
                        } else {
                            OptionsToSelect.push(val);
                        }
                    }
                }
                return {
                    ...pair,
                    value: OptionsToSelect,
                };
            }
            return pair;
        });
    }

    public getUrlElementsIds(): ElementId[] {
        return this.elements
            .filter((element) => FORM_ELEMENT_MEDIA.includes(element.type))
            .map((element) => element.id);
    }

    public getIdByElementType(type: FormElementType): string | undefined {
        return this.getElementByType(type)?.id;
    }

    public getElementById(id: string): FormElement | undefined {
        return this.elements.find((element) => element.id === id);
    }

    private sortEls(): void {
        this.elements.sort((e1, e2) => e1.position - e2.position);
        this.elements.forEach((element) => {
            this.sortOptions(element);
        });
    }

    private sortOptions(element: FormElement): void {
        if (Array.isArray(element.options)) {
            element.options.sort((o1, o2) => o1.position - o2.position);
        }
    }

    public getHiddenElementsPerVisibilityRules(): (ElementId | SubElementId)[] {
        const hiddenElements = this.elements.filter((element) => {
            return element.visible_conditions && !element.isMatchingRules;
        });
        const addressElements = this.getElementsByType(FormElementType.address);

        const elementsIds: (ElementId | SubElementId)[] = hiddenElements.map((element) => element.id);

        if (addressElements.length) {
            const firstAddressElement = addressElements[0];
            for (const subElement of firstAddressElement.sub_elements) {
                if (!subElement.visible) {
                    elementsIds.push(subElement.sub_element_id);
                }
            }
        }

        return elementsIds;
    }

    public getHiddenElements(isActivation: boolean): (ElementId | SubElementId)[] {
        const hidden = this.getHiddenElementsPerVisibilityRules().concat(
            this.getHiddenElementsPerFormType(isActivation),
        );

        const hiddenElementsIds = hidden.map((id) => {
            if (Util.isSubElementId(id)) return Util.getParentId(id);
            return id;
        });
        /*
         we need to add each element to hidden list, if its parent is hidden
         */
        hiddenElementsIds.forEach((parentId) =>
            this.elements
                .filter((el) => el.parent_element_id === parentId)
                .forEach((el) => {
                    hidden.push(el.id);
                }),
        );
        const hiddenSet = new Set(hidden);
        return Array.from(hiddenSet);
    }

    public getHiddenElementsPerFormType(isActivation: boolean): ElementId[] {
        const hiddenElements = this.elements.filter((element) => {
            return isActivation ? !element.available_in_activations : !element.available_in_event_form;
        });

        let elementsIds: ElementId[] = [];
        for (const element of hiddenElements) {
            elementsIds = elementsIds.concat(element.id);
        }
        return elementsIds;
    }

    public getStationById(stationId: string | number): Station | undefined {
        return this.event_stations.find((station) => station.id == stationId);
    }

    public getScanSources(): ScanSource[] {
        const sources: ScanSource[] = [];
        const businessCardElement = this.getElementByType(FormElementType.business_card);
        const barcodeElement = this.getElementByType(FormElementType.barcode);
        if (businessCardElement) {
            sources.push({ element: businessCardElement, name: "form-capture.business-card", icon: "card-outline" });
        }
        if (barcodeElement) {
            sources.push({ element: barcodeElement, name: "form-capture.badge-scan", icon: "scan-outline" });
        }
        return sources;
    }

    public isTranscriptionEnabled(): boolean {
        const businessCardEl = this.getElementByType(FormElementType.business_card);

        if (!businessCardEl) return false;
        return businessCardEl.is_enable_transcription == 1;
    }

    public getRatingColors(): RatingColors {
        let labels;
        const ratingElement = this.getElementByType(FormElementType.rating);

        if (ratingElement?.labels) {
            labels = ratingElement.labels;
        } else {
            const ratingSubmissionAction = this.getSubmissionActionForType(SubmissionActionTypes.RATING);

            if (ratingSubmissionAction?.labels) {
                labels = ratingSubmissionAction?.labels;
            }
        }

        const temp = new RatingColors();

        if (!labels) return temp;

        labels.forEach((label) => {
            temp[label.type.toLowerCase()] = label.color;
        });
        return temp;
    }

    setFormRatingColors(): void {
        const colors = this.getRatingColors();
        document.documentElement.style.setProperty(`--rating-urgent`, colors.urgent);
        document.documentElement.style.setProperty(`--rating-hot`, colors.hot);
        document.documentElement.style.setProperty(`--rating-warm`, colors.warm);
        document.documentElement.style.setProperty(`--rating-cold`, colors.cold);
    }

    public getSubmissionActionForType<T extends SubmissionActionTypes>(type: T): SubmissionActionMap[T] | undefined {
        return this.submission_actions.find((action) => action.type === type) as SubmissionActionMap[T] | undefined;
    }

    public clone(): this {
        return _cloneDeep(this);
    }

    public setTheme(): void {
        this.setBrowserTheme();
        this.setFormRatingColors();
        if (!this.event_style.theme_color) return;
        const theme = new Theme(this.event_style.theme_color);

        Object.keys(theme.properties).forEach((e) => {
            // console.log(e, theme.properties[e]);
            document.documentElement.style.setProperty(e, theme.properties[e]);
        });
    }

    setBrowserTheme(): void {
        const metaTag = document.querySelector('meta[name="theme-color"]');
        metaTag?.setAttribute("content", this.event_style.theme_color || "#f0322b");
    }

    public getFormActionMode(): ActionModes {
        return this.is_mobile_kiosk_mode ? ActionModes.KIOSK_MODE : ActionModes.NORMAL_CAPTURE;
    }
}

export function getIdByUniqueElementName(
    name: LL_FIELD_UNIQUE_IDENTIFIER,
    elements: Pick<FormElement, "id" | "sub_elements" | "mapping">[],
): ElementOrSubElementId | null {
    for (const element of elements) {
        if (element.mapping.length == 0) {
            continue;
        }

        if (element.sub_elements.length) {
            for (const item of element.mapping as SubElementMapping[]) {
                if (item.ll_field_unique_identifier == name) {
                    return item.sub_element_id;
                }
            }
        } else {
            for (const item of element.mapping) {
                if (item.ll_field_unique_identifier == name) {
                    return element.id;
                }
            }
        }
    }

    return null;
}

export interface ScanSource {
    name: string;
    element: FormElement;
    icon: string;
}

export enum CTABtnPosition {
    fixed = "0",
    bottom = "1",
    hidden = "2",
}

export enum CTABtnStyle {
    fitText = "fit_text",
    fullWidth = "full_width",
}

export enum CTABtnAlignment {
    left = "left",
    right = "right",
    center = "center",
}

export enum FormMode {
    preview = "preview",
    submit = "submit",
    edit = "edit",
    view = "view",
}
