import { Injectable } from "@angular/core";
import * as Sentry from "@sentry/capacitor";
import { Event } from "@sentry/capacitor";

import { Severity } from "../model/SentryTypes";

@Injectable({
    providedIn: "root",
})
export class AnalyticsService {
    // sentry
    public static captureMessage({
        message,
        level,
        tags,
        data,
    }: {
        message: string;
        level: Severity;
        tags?: Record<string, any>;
        data?: Record<string, unknown>;
    }): string {
        return Sentry.captureMessage(message, (scope) => {
            scope.setLevel(level);
            if (data) {
                scope.setContext("extra-data", data);
            }

            if (tags) {
                for (const key in tags) {
                    scope.setTag(key, tags[key]);
                }
            }
            return scope;
        });
    }

    public static captureException({
        error,
        level,
        message,
        tags,
        data,
    }: {
        error: any;
        level: Severity;
        message?: string;
        tags?: Record<string, any>;
        data?: Record<string, unknown>;
    }): void {
        Sentry.captureException(error, (scope) => {
            scope.setLevel(level);

            if (message) {
                scope.addBreadcrumb({
                    type: "error", // predefined types
                    category: "error",
                    level: Severity.Error,
                    message: message,
                });
            }

            if (data) {
                scope.setContext("extra-data", data);
            }

            if (tags) {
                for (const key in tags) {
                    scope.setTag(key, tags[key]);
                }
            }

            return scope;
        });
    }

    public static captureEvent({ event }: { event: Event }): string {
        return Sentry.captureEvent(event);
    }

    // public static captureExceptionWithScope(
    //     error: Error,
    //     level: Severity = Severity.Error,
    //     extras: Record<string, Primitive> = {},
    //     tags: Record<string, Primitive> = {}
    // ): void {
    //     Sentry.withScope(function (scope) {
    //         scope.setLevel(level);
    //
    //         for (const key in tags) {
    //             scope.setTag(key, tags[key]);
    //         }
    //         for (const key in extras) {
    //             scope.setExtra(key, extras[key]);
    //         }
    //         Sentry.captureException(error);
    //     });
    // }

    public static addBreadcrumb(category: string, message: string, level: Severity = Severity.Error): void {
        Sentry.addBreadcrumb({
            category: category,
            message: message,
            level: level,
        });
    }
}
