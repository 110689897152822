import { inject, Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";

import { ToastTheme } from "../../constants/toast-theme";
import { Form, FormSubmission } from "../../model";
import { Severity } from "../../model/SentryTypes";
import { OUTBOUND_MESSAGES } from "../../model/messages";
import { SubmitFormResponse } from "../../model/submitFormResponse";
import { Util } from "../../util/util";
import { AnalyticsService } from "../analytics.service";
import { ApiService } from "../api.service";
import { PopupService } from "../popup.service";
import { FilesToUploadType, UploadService } from "../upload.service";

@Injectable({
    providedIn: "root",
})
export class SubmissionService {
    private api = inject(ApiService);
    private popup = inject(PopupService);
    private uploadService = inject(UploadService);
    private route = inject(ActivatedRoute);
    data: FormSubmission[] = [];
    private duplicateLeadSource = new BehaviorSubject<{ form: Form; submission: FormSubmission } | null>(null);
    duplicateLead: Observable<{ form: Form; submission: FormSubmission }> = this.duplicateLeadSource.asObservable();

    async sendFilesToUpload(sub: FormSubmission, form: Form): Promise<void> {
        await Promise.all(
            form.getUrlElementsIds().map(async (elementId) => {
                const subData = sub.data[elementId] as FilesToUploadType;
                if (subData) {
                    sub.data[elementId] = await this.uploadService.uploadDataUri(subData);
                }
            }),
        );
    }

    async actuallySubmitForm(submission: FormSubmission, form: Form): Promise<SubmitFormResponse> {
        try {
            await this.sendFilesToUpload(submission, form);
            submission.query_parameters = Util.getQueryStrings(this.route.snapshot, "act_");

            return await this.api.submitForm(submission, form).toPromise();
        } catch (error) {
            this.popup.showToast({ text: "toast.something-went-wrong", params: {} }, "top", ToastTheme.Error);
            AnalyticsService.captureException({
                error,
                level: Severity.Error,
                message: "error while uploading",
                data: {
                    submission: submission,
                },
                tags: {
                    formId: form.form_id,
                },
            });
        }
    }

    async postEmbeddedForm(submission: FormSubmission, form: Form): Promise<void> {
        try {
            await this.sendFilesToUpload(submission, form);
            submission.query_parameters = Util.getQueryStrings(this.route.snapshot, "act_");
            const clonedSubmissions = submission.clone();
            Util.customPostMessage(
                JSON.stringify({ type: OUTBOUND_MESSAGES.SUBMISSION_BODY, data: clonedSubmissions }),
                "*",
            );
        } catch (e) {
            //
            Util.customPostMessage(
                JSON.stringify({
                    type: OUTBOUND_MESSAGES.FORM_ERROR_MESSAGE,
                    data: "Something went wrong while uploading the submission's files",
                }),
                "*",
            );
        }
    }
}
