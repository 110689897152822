import { LL_FIELD_ID, LL_FIELD_UNIQUE_IDENTIFIER } from "../constants/transcription-fields";

import { BaseResponse } from "./protocol/response";
import { SubmissionResponse } from "./protocol/submission-response";

export interface BaseItemData {
    ll_field_unique_identifier: LL_FIELD_UNIQUE_IDENTIFIER;
    // will be boolean only with the PiiOptOut field
    value: string;
}
export interface ItemData extends BaseItemData {
    ll_field_id: LL_FIELD_ID;
}
export enum FillFormModes {
    kiosk = "kiosk",
    event_webview = "event_webview",
    activation_webview = "activation_webview",
}
interface NotDuplicatedSubmissionBadgeResponse extends BaseResponse {
    info: ItemData[];
    action: "default";
    is_duplicate: false;
}

interface BaseDuplicatedBadgeResponse extends BaseResponse {
    info: ItemData[];
    submission: SubmissionResponse;
    is_fill_from_existing: boolean;
    is_duplicate: true;
}
interface FillAndEditFromBadgeResponse extends BaseDuplicatedBadgeResponse {
    action: "edit_submission";
    display_mode: "view" | "edit";
    is_fill_from_existing: true;
    fill_from_existing_modes: FillFormModes[];
    scan_duplicate_message: string;
}
interface FillAndDontEditFromBadgeResponse extends BaseDuplicatedBadgeResponse {
    is_fill_from_existing: true;
    action: "default";
    fill_from_existing_modes: FillFormModes[];
    scan_duplicate_message: string;
}
interface DontFillAndEditFromBadgeResponse extends BaseDuplicatedBadgeResponse {
    is_fill_from_existing: false;
    action: "edit_submission";
    display_mode: "view" | "edit";
}

export type DuplicatedBadgeResponse =
    | FillAndEditFromBadgeResponse
    | FillAndDontEditFromBadgeResponse
    | DontFillAndEditFromBadgeResponse;

export type BadgeResponse = NotDuplicatedSubmissionBadgeResponse | DuplicatedBadgeResponse;

export interface BaseInvalidBadgeResponse {
    status: 400;
    message: string;
    time_to_sleep: number;
    api_call_snooze: number;
}

export interface BaseDuplicatedInvalidBadgeResponse extends BaseInvalidBadgeResponse {
    action: "edit_submission" | "default";
    is_duplicate: true;
    is_pending: boolean;
    display_mode: "view" | "edit";
    submission: SubmissionResponse;
}

export type InvalidBadgeResponse = BaseInvalidBadgeResponse | BaseDuplicatedInvalidBadgeResponse;
