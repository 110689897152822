import { Component, inject, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ModalController } from "@ionic/angular";

@Component({
    selector: "app-form-instructions",
    templateUrl: "./form-instructions.page.html",
    styleUrls: [],
})
export class FormInstructionsPage implements OnInit {
    private modalCtrl = inject(ModalController);
    private activatedRoute = inject(ActivatedRoute);
    @Input() instructionName: string;
    @Input() instructionsContent: string;
    isModal: boolean = false;

    ngOnInit(): void {
        if (this.instructionName || this.instructionsContent) {
            this.isModal = true;
            return;
        }
        this.activatedRoute.queryParams.subscribe((d) => {
            this.instructionName = d.instructionName;
            this.instructionsContent = d.instructionContent;
        });
    }

    onDismiss(): void {
        this.modalCtrl.dismiss(null);
    }
}
