import { Util } from "../util/util";

import { Verification } from "./verification";

export class DateVerification extends Verification {
    date: number = 0;
    days: number = 0;

    is_enable_date_verification: boolean = false;
    is_enable_under_date_verification: boolean = false;
    is_enable_over_date_verification: boolean = false;
    is_enable_date_range_verification: boolean = false;

    under_date_verification_message: string = "";
    over_date_verification_message: string = "";
    date_range_verification_message: string = "";
    under_date_verification_title: string = "";
    over_date_verification_title: string = "";
    date_range_verification_title: string = "";

    verified_under_date: number = 0;
    verified_over_date: number = 0;
    date_until_verified: number = 0;
    date_over_verified: number = 0;

    start_verified_date: number = 0;
    end_verified_date: number = 0;

    is_show_option_to_proceed_date: boolean = false;

    date_verification_future: number;

    public getAlertMsg(): { title: string; msg: string } | null {
        const data = this.getAlertObject();
        if (data) data.msg = Util.replaceStringTokens(data.msg, this);
        return data;
    }

    public setVerificationAttrs(date: string): void {
        this.date = new Date(date).getTime();
        const diff = Date.now() - this.date;
        this.days = Math.floor(diff / (1000 * 60 * 60 * 24)); // convert to days
        this.verification_attr = this.days / 365.25; // convert to years
        this.verified_over = this.verified_over_date;
        this.verified_under = this.verified_under_date;
        this.date_until_verified = Math.abs(this.verified_under - this.days);
        this.date_over_verified = Math.abs(this.days - this.verified_over);
        this.end_verified = this.end_verified_date;
        this.start_verified = this.start_verified_date;
        this.is_enable_verification = this.is_enable_date_verification;
        this.is_enable_over_verification = this.is_enable_over_date_verification;
        this.is_enable_under_verification = this.is_enable_under_date_verification;
        this.is_enable_range_verification = this.is_enable_date_range_verification;
        this.over_verification_message = this.over_date_verification_message;
        this.under_verification_message = this.under_date_verification_message;
        this.range_verification_message = this.date_range_verification_message;
        this.is_show_option_to_proceed = this.is_show_option_to_proceed_date;
        this.over_title = this.over_date_verification_title;
        this.under_title = this.under_date_verification_title;
        this.within_title = this.date_range_verification_title;
    }
}
