import { NgModule } from "@angular/core";

import { FileExtensionPipe } from "./file-extension.pipe";
import { FormBackgroundStylesPipe } from "./form-background-styles.pipe";
import { GenerateArrayPipe } from "./generate-array.pipe";
import { IncludesPipe } from "./includes.pipe";
import { IsSameDayPipe } from "./is-same-day.pipe";
import { IsVideoUrlPipe } from "./is-video-url.pipe";
import { FilterByKeyValuePipe } from "./lodash/filter-by-key-value.pipe";
import { JoinWordsByPipe } from "./lodash/join-words-by.pipe";
import { MapByKeyPipe } from "./lodash/map-by-key.pipe";
import { MapToPipe } from "./lodash/map-to.pipe";
import { NumberTimeConverterPipe } from "./num-time-cnvrter.pipe";
import { RateIconPipe } from "./rate-icon.pipe";
import { SafeHtmlPipe } from "./safe-html.pipe";
import { SafeUrlPipe } from "./safe-url.pipe";
import { SortByPipe } from "./sort-by.pipe";
import { SplitFormElementsPipe } from "./split-form-elements.pipe";
import { TimeIntervalPipe } from "./time-interval.pipe";
import { ShouldRenderLookupButtonPipe } from "./should-render-lookup-button.pipe";
import { ShouldBeDisabledByMaxPipe } from "./should-be-disabled-by-max.pipe";
import { GetMultiPickListHintPipe } from "./get-multi-pick-list-hint.pipe";
import { GetOrderElementValuePipe } from "./get-order-element-value.pipe";
import { FindByKeyPathPipe } from "./lodash/find-by-key-path.pipe";
import { IsHorizontalSurveyPipe } from "./is-horizontal-survey.pipe";

@NgModule({
    declarations: [
        SafeHtmlPipe,
        SafeUrlPipe,
        SortByPipe,
        RateIconPipe,
        TimeIntervalPipe,
        FileExtensionPipe,
        FilterByKeyValuePipe,
        MapToPipe,
        JoinWordsByPipe,
        MapByKeyPipe,
        IsVideoUrlPipe,
        NumberTimeConverterPipe,
        SplitFormElementsPipe,
        FormBackgroundStylesPipe,
        IncludesPipe,
        GenerateArrayPipe,
        IsSameDayPipe,
        ShouldRenderLookupButtonPipe,
        ShouldBeDisabledByMaxPipe,
        GetMultiPickListHintPipe,
        ShouldRenderLookupButtonPipe,
        GetOrderElementValuePipe,
        FindByKeyPathPipe,
        IsHorizontalSurveyPipe,
    ],
    imports: [],
    exports: [
        SafeHtmlPipe,
        SafeUrlPipe,
        SortByPipe,
        RateIconPipe,
        TimeIntervalPipe,
        FileExtensionPipe,
        FilterByKeyValuePipe,
        MapToPipe,
        JoinWordsByPipe,
        MapByKeyPipe,
        IsVideoUrlPipe,
        NumberTimeConverterPipe,
        SplitFormElementsPipe,
        FormBackgroundStylesPipe,
        IncludesPipe,
        GenerateArrayPipe,
        IsSameDayPipe,
        ShouldRenderLookupButtonPipe,
        ShouldBeDisabledByMaxPipe,
        GetMultiPickListHintPipe,
        GetOrderElementValuePipe,
        FindByKeyPathPipe,
        IsHorizontalSurveyPipe,
    ],
})
export class PipesModule {}
