<ion-content>
    <ion-text [color]="color">
        <div class="centered">
            <div class="ion-padding" [innerHTML]="message | safeHtml"></div>
            @if (actions?.length) {
                <ion-buttons class="flex ion-justify-content-center">
                    @for (action of actions; track action) {
                        <ion-button
                            [style]="{ '--background': action.info.background_color, '--color': action.info.color }"
                            size="large"
                            (click)="takeAction(action.action)"
                            >{{ action.info.text }}
                        </ion-button>
                    }
                </ion-buttons>
            }
        </div>
    </ion-text>
</ion-content>
