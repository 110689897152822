import { ScrollingModule } from "@angular/cdk/scrolling";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";

import { PipesModule } from "../pipes/pipes.module";

import { AccordionComponent } from "./accordion/accordion.component";
import { AssignOwnerComponent } from "./form-view/elements/assign-owner/assign-owner.component";
import { CheckboxesModalComponent } from "./form-view/elements/checkboxes-modal/checkboxes-modal.component";
import { CheckboxesRankComponent } from "./form-view/elements/checkboxes-rank/checkboxes-rank.component";
import { MeetingItemComponent } from "./meeting-item/meeting-item.component";
import { SelectFormUserComponent } from "./select-form-user/select-form-user.component";
import { LookupsComponent } from "./lookups/lookups.component";
import { FormFieldsVerificationsComponent } from "./form-field-verifications/form-fields-verifications.component";
import { GenericOrderOptionComponent } from "./generic-order-option/generic-order-option.component";
import { GenericSurveyOptionComponent } from "./generic-survey-option/generic-survey-option.component";

@NgModule({
    declarations: [
        SelectFormUserComponent,
        AssignOwnerComponent,
        MeetingItemComponent,
        CheckboxesModalComponent,
        CheckboxesRankComponent,
        AccordionComponent,
        GenericOrderOptionComponent,
        LookupsComponent,
        FormFieldsVerificationsComponent,
        LookupsComponent,
        GenericSurveyOptionComponent,
        GenericOrderOptionComponent,
    ],
    exports: [
        SelectFormUserComponent,
        AssignOwnerComponent,
        MeetingItemComponent,
        CheckboxesModalComponent,
        CheckboxesRankComponent,
        AccordionComponent,
        GenericOrderOptionComponent,
        LookupsComponent,
        FormFieldsVerificationsComponent,
        GenericOrderOptionComponent,
        GenericSurveyOptionComponent,
    ],
    imports: [
        FormsModule,
        CommonModule,
        IonicModule,
        PipesModule,
        TranslateModule.forChild(),
        ScrollingModule,
        ReactiveFormsModule,
    ],
})
export class ComponentModule {}
