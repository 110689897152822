import { Injectable } from "@angular/core";

import { SoundsSourceMap } from "../model/sounds";

@Injectable({
    providedIn: "root",
})
export class UserFeedbackService {
    public isKioskMode: boolean = false;
    public isRapidScanMode: boolean = false;

    private audioPlayer: HTMLAudioElement = new Audio();

    preload(key: string): Promise<void> {
        this.audioPlayer.src = SoundsSourceMap[key];
        return this.audioPlayer.play();
    }

    play(key: string): Promise<void> {
        this.audioPlayer.src = SoundsSourceMap[key];
        return this.audioPlayer.play();
    }

    vibrate(duration: number): void {
        if (!window) return;

        if (!window.navigator) return;

        if (!window.navigator.vibrate) return;
        navigator.vibrate(duration);
    }
}
