import { ItemData } from "./badge";
import { FormSubmission } from "./form-submission";

export enum OUTBOUND_MESSAGES {
    FORM_LOAD_COMPLETE = "form_load_complete",
    FORM_ERROR_MESSAGE = "form_error_message",
    SUBMISSION_BODY = "submission_body",
}

export enum INBOUND_MESSAGES_TYPES {
    SUBMIT = "submit_form",
    RESET = "reset_form",
    FORM_PREFILL = "form_prefill",
}

export enum SubmissionDataType {
    ULC_SUBMISSION = "ulc_submission",
    INFO = "info",
    ULC_SUBMISSION_INFO = "ulc_submission_and_info",
}

export type INBOUND_MESSAGES =
    | {
          type: INBOUND_MESSAGES_TYPES.SUBMIT;
      }
    | {
          type: INBOUND_MESSAGES_TYPES.RESET;
      }
    | {
          type: INBOUND_MESSAGES_TYPES.FORM_PREFILL;
          data_type: SubmissionDataType.ULC_SUBMISSION;
          data: FormSubmission;
      }
    | {
          type: INBOUND_MESSAGES_TYPES.FORM_PREFILL;
          data_type: SubmissionDataType.INFO;
          data: ItemData[];
      }
    | {
          type: INBOUND_MESSAGES_TYPES.FORM_PREFILL;
          data_type: SubmissionDataType.ULC_SUBMISSION_INFO;
          data: { submission?: FormSubmission; info?: ItemData[] };
      };
