import { AlertButton } from "@ionic/core";

const UNDER = -1;
const OVER = 1;
const IN = 0;
const WITHIN = 2;

export class Verification {
    protected verification_attr: number;

    protected is_enable_verification: boolean;
    protected is_enable_under_verification: boolean;
    protected is_enable_over_verification: boolean;
    protected is_enable_range_verification: boolean;

    protected verified_under: number;
    protected verified_over: number;
    protected start_verified: number;
    protected end_verified: number;

    protected under_verification_message: string;
    protected over_verification_message: string;
    protected range_verification_message: string;

    protected is_show_option_to_proceed: boolean;

    protected under_title: string;
    protected over_title: string;
    protected within_title: string;

    private getStatus() {
        if (this.is_enable_under_verification && this.verification_attr <= this.verified_under) {
            return UNDER;
        } else if (this.is_enable_over_verification && this.verification_attr >= this.verified_over) {
            return OVER;
        } else if (
            this.is_enable_range_verification &&
            this.verification_attr >= this.start_verified &&
            this.verification_attr <= this.end_verified
        )
            return WITHIN;
        else return IN;
    }

    protected getAlertObject(): { title: string; msg: string } | null {
        switch (this.getStatus()) {
            case UNDER:
                return this.createAlertObject(this.under_title, this.under_verification_message);
            case OVER:
                return this.createAlertObject(this.over_title, this.over_verification_message);
            case WITHIN:
                return this.createAlertObject(this.within_title, this.range_verification_message);
            default:
                return null;
        }
    }

    private createAlertObject(title: string, msg: string) {
        return { title, msg };
    }

    public getAlertBtns(handler: (value: any) => void, resetBtnName: () => void): AlertButton[] {
        const btns: AlertButton[] = [{ text: "OK", role: "cancel", handler: () => resetBtnName() }];
        if (this.is_show_option_to_proceed) btns.push({ text: "Proceed", handler });
        return btns;
    }
}
