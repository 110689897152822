import { fromEvent, merge, of, Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";

export class InteractionsDetector {
    private delay: number;
    private timer: number;
    private callback: () => unknown;
    private interactions = [
        fromEvent(document.body, "mousemove"),
        fromEvent(document.body, "scroll"),
        fromEvent(document.body, "keydown"),
        fromEvent(document.body, "keyup"),
        fromEvent(document.body, "click"),
        fromEvent(document.body, "touchstart"),
        fromEvent(document.body, "touchmove"),
        fromEvent(document.body, "touchdown"),
        of("enter"),
    ];
    private $interactions: Subscription | null;

    public ListenForInteraction(): void {
        if (!this.$interactions)
            this.$interactions = merge(...this.interactions)
                .pipe(debounceTime(500))
                .subscribe(() => {
                    this.repeatTimer();
                });
    }

    public setCallback(callback: () => unknown): void {
        this.callback = callback;
    }

    public setDelay(delay: number): void {
        this.delay = delay * 1000;
    }

    private clearTimer() {
        if (this.timer) clearTimeout(this.timer);
    }

    private repeatTimer() {
        this.clearTimer();
        this.timer = setTimeout(() => this.callback(), this.delay);
    }

    public stopListenForInteraction(): void {
        this.clearTimer();
        if (this.$interactions) {
            this.$interactions.unsubscribe();
            this.$interactions = null;
        }
    }
}
