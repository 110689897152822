import { HexColor } from "../util/typeAliases";

export enum SubmissionActionTypes {
    RATING = "rating",
    SEND_RHYTHMS = "send_to_rhythms",
    SEND_AUTOMATIONS = "send_to_automations",
    FETCH_RELATED_PROSPECTS = "fetch_related_prospects",
    ASSIGN_OWNER = "assign_owner",
}

// rating
export enum RatingValues {
    NONE = "",
    HOT = "Hot",
    COLD = "Cold",
    URGENT = "Urgent",
    WARM = "Warm",
}

export interface RatingSetting {
    allow_no_rating: boolean;
}

export type ratingLabel = { type: RatingValues; value: string; color: HexColor };

export interface RatingSubmissionAction {
    type: SubmissionActionTypes.RATING;
    setting: RatingSetting;
    default_value?: RatingValues;
    labels?: ratingLabel[];
}

// rhythm
export interface itemSubmissionAction {
    id: RatingValues;
    name: string;
    label?: string;
    color?: HexColor;
}

interface AddToRhythemSubmissionAction {
    type: SubmissionActionTypes.SEND_RHYTHMS;
    items: itemSubmissionAction[];
}

// automation
interface AddToAutomationSubmissionAction {
    type: SubmissionActionTypes.SEND_AUTOMATIONS;
    items: itemSubmissionAction[];
}

//fetch related prospects
interface FetchRelatedProspectsSubmissionAction {
    type: SubmissionActionTypes.FETCH_RELATED_PROSPECTS;
}

export interface AssignOwnerSubmissionAction {
    type: SubmissionActionTypes.ASSIGN_OWNER;
    // For Example, either 1992 or "1992" from backend
    defaultValue?: number | string;
    required: boolean;
}

export type SubmissionAction =
    | RatingSubmissionAction
    | AddToRhythemSubmissionAction
    | AddToAutomationSubmissionAction
    | FetchRelatedProspectsSubmissionAction
    | AssignOwnerSubmissionAction;

export type SubmissionActionMap = {
    [SubmissionActionTypes.RATING]: RatingSubmissionAction;
    [SubmissionActionTypes.ASSIGN_OWNER]: AssignOwnerSubmissionAction;
    [SubmissionActionTypes.FETCH_RELATED_PROSPECTS]: FetchRelatedProspectsSubmissionAction;
    [SubmissionActionTypes.SEND_RHYTHMS]: AddToRhythmSubmissionAction;
    [SubmissionActionTypes.SEND_AUTOMATIONS]: AddToAutomationSubmissionAction;
};

interface AddToRhythmSubmissionAction {
    type: SubmissionActionTypes.SEND_RHYTHMS;
    items: itemSubmissionAction[];
}

export type SubmissionActionData = {
    [SubmissionActionTypes.RATING]?: RatingValues;
    [SubmissionActionTypes.SEND_RHYTHMS]?: string | string[];
    [SubmissionActionTypes.SEND_AUTOMATIONS]?: string | string[];
    [SubmissionActionTypes.FETCH_RELATED_PROSPECTS]?: boolean;
    [SubmissionActionTypes.ASSIGN_OWNER]?: string | number;
};
