import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "sortBy",
})
export class SortByPipe implements PipeTransform {
    transform(value: any, ...args: any[]): undefined | any[] {
        const ascending = args[1] || 1;
        if (value) {
            return value.sort((a: any, b: any) => {
                const date1 = new Date(a[args[0]]),
                    date2 = new Date(b[args[0]]);
                if (date1 > date2) {
                    return 1 * ascending;
                } else if (date1 < date2) {
                    return -1 * ascending;
                } else {
                    return 0;
                }
            });
        }
    }
}
