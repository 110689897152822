import { Component, inject, Input, OnInit } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { ModalController } from "@ionic/angular";
import { from, fromEvent, Observable, Subscription } from "rxjs";
import { concatMap, tap } from "rxjs/operators";

import { ACTIVATIONS_ACTIONS } from "../../constants/activations";
import { ToastTheme } from "../../constants/toast-theme";
import { Activation, Form, GameResult } from "../../model";
import { Severity } from "../../model/SentryTypes";
import { ProspectTrackingInfo } from "../../model/prospect-tracking-info";
import { ActivationSettingResponse, FormSubmitResponse } from "../../model/protocol/form-submit-response";
import { AnalyticsService } from "../../services/analytics.service";
import { ApiService } from "../../services/api.service";
import { FormViewService } from "../../services/form-view.service";
import { PopupService } from "../../services/popup.service";
import { Util } from "../../util/util";

async function sleep(time: number) {
    return new Promise<void>((res) => {
        setTimeout(() => res(), time);
    });
}

@Component({
    selector: "app-activation-element",
    templateUrl: "./activation-element.page.html",
    styleUrls: [],
    providers: [{ provide: FormViewService, useValue: window["formViewService"] }],
})
export class ActivationElementPage implements OnInit {
    public activatedRoute = inject(ActivatedRoute);
    private api = inject(ApiService);
    private popup = inject(PopupService);
    private modal = inject(ModalController);
    @Input() activation: Activation;
    @Input() form: Form;
    @Input() prospect: ProspectTrackingInfo;
    @Input() prospectId: number;
    @Input() activityId: number;
    @Input() formViewService: FormViewService;
    activationUrl: string;
    isLoading: boolean = null;
    isGameloaded: boolean = false;
    onGameEndSubs: Subscription;
    reload: boolean;
    online: boolean = true;
    message: string = "";
    showSuccessMsg: boolean = false;
    activationSettings: ActivationSettingResponse;

    loaded(): void {
        setTimeout(() => {
            this.isLoading = false;
        }, 1000);
    }

    ngOnInit(): void {
        this.prepareActivationUrl();
        this.reloadGame();
        this.listenToGameEvents();
    }

    prepareActivationUrl(): void {
        const allParams: Params = this.activatedRoute.snapshot.queryParams;
        let string = "";
        if (Object.keys(allParams).length) {
            for (const key in allParams) {
                if (key.startsWith("act_")) {
                    string += "&" + key + "=" + allParams[key];
                }
            }
        }
        this.activationUrl = `${this.activation.url}&prospect_id=${this.prospectId || 0}&is_web_portal=1${string}`;
    }

    ionViewWillLeave(): void {}

    reloadGame(): void {
        this.reload = true;
        this.isLoading = null;
        setTimeout(() => {
            this.reload = false;
        }, 200);
    }

    listenToGameEvents(): void {
        const gameEvent = fromEvent(window, "message");
        this.onGameEndSubs = gameEvent
            .pipe(
                concatMap((msg: MessageEvent) => {
                    return from(this.onGameEnd(msg));
                }),
            )
            .subscribe({
                next: () => {},
                error: (error) => {
                    AnalyticsService.captureException({ error, level: Severity.Error });
                },
            });
    }

    async onGameEnd(data: MessageEvent): Promise<void> {
        const activityId = this.prospect?.activation_action?.activity_id || this.activityId;
        const prospectId = this.prospectId;
        const result: GameResult = { ...data.data };
        if (result.activation_result) {
            this.stopLoading();
            switch (result.action) {
                case ACTIVATIONS_ACTIONS.NEXT:
                    if (this.didUserFillFormBefore()) {
                        this.executeNEXT();
                    } else {
                        this.endGame(false);
                    }
                    break;
                case ACTIVATIONS_ACTIONS.SUBMIT:
                    this.formViewService.setActivationResult(result.activation_result);
                    if (this.didUserFillFormBefore()) {
                        await this.submitActivation(activityId, prospectId).toPromise();
                    }
                    break;
                case ACTIVATIONS_ACTIONS.SUBMIT_NEXT:
                    this.formViewService.setActivationResult(result.activation_result);
                    if (this.didUserFillFormBefore()) {
                        this.submitActivation(activityId, prospectId).subscribe(() => {
                            this.executeNEXT();
                        });
                    } else {
                        this.endGame(true);
                    }
                    break;
                case ACTIVATIONS_ACTIONS.EXIT:
                    break;
                case ACTIVATIONS_ACTIONS.LOAD_COMPLETE:
                    break;
                case ACTIVATIONS_ACTIONS.BACK:
                    this.goBack();
                    break;
                default:
                    break;
            }
        }
    }

    // some games don't send load_complete, so we have to do it the hard way
    stopLoading(): void {
        this.isGameloaded = true;
        this.formViewService.loaded = true;
    }

    didUserFillFormBefore(): boolean {
        return !!this.prospect?.activation_action?.activity_id || !!this.activityId;
    }

    async executeNEXT(): Promise<void> {
        await this.afterActivationSubmission();
    }

    ionViewDidLeave(): void {
        if (this.onGameEndSubs) this.onGameEndSubs.unsubscribe();
    }

    goBack(): void {
        const buttons = [
            {
                text: "general.cancel",
                role: "cancel",
            },
            {
                text: "general.ok",
                handler: () => {
                    this.endGame(false);
                },
            },
        ];
        this.popup.showAlert("Warning", { text: "alerts.activation.message" }, buttons);
    }

    private async endGame(isNext: boolean = false): Promise<void> {
        // if (!isNext && this.activation.activation_capture_form_after) {
        //     await this.navCtrl.pop();
        //     await this.modal.dismiss();
        //     return;
        // }
        await this.modal.dismiss({ isNext });
    }

    submitActivation(activityId: number, prospectId: number): Observable<FormSubmitResponse> {
        const stationId = this.formViewService.selectedStation?.id;
        return this.api
            .submitActivation({
                activation_id: this.activation.id,
                activation_result: this.formViewService.activationResult,
                activity_id: activityId,
                prospect_id: prospectId,
                ...(stationId && { station_id: +stationId }),
            })
            .pipe(
                tap((data) => {
                    if (data) {
                        this.showSuccessMsg = true;
                        this.activationSettings = data.activation;
                        // this.afterActivationSubmission(data.message, isNext);
                    }
                }),
            );
    }

    private async afterActivationSubmission(): Promise<void> {
        // this.activation.count_of_submissions++;
        // if (isNext) this.endGame(true);
        if (this.activationSettings.webviewSuccessActionType == "start_over") {
            if (this.showSuccessMsg) {
                this.popup.showToast(
                    { text: this.activationSettings.webviewSuccessMessage || "Submission sent successfully" },
                    "bottom",
                    ToastTheme.Success,
                );
            }

            setTimeout(() => {
                location.reload();
            }, 300);
            await sleep(4000);
        } else if (this.activationSettings.webviewSuccessActionType == "message") {
            this.formViewService.openSuccessPage(this.activation.webview_success_message);
        } else if (this.activationSettings.webviewSuccessActionType == "meeting") {
            this.formViewService.openMeetingAutomation(null, false);
            this.formViewService.setActivationResult();
        } else {
            let url = this.activationSettings.webviewSuccessRedirectUrl;
            if (this.activation.append_prospect_id_to_redirect_url) {
                url = url + "&pid=" + this.prospectId;
            }
            Util.processUrlThenRedirect(url);
        }
    }

    // retryForSubmitActivation(isNext: boolean = false, resultAction: string): void {
    //     const buttons = [
    //         {
    //             text: "general.back",
    //             handler: () => {
    //                 this.goBack();
    //             },
    //         },
    //         {
    //             text: "alerts.activation.retry",
    //             handler: () => {
    //                 this.submitActivation(isNext, resultAction);
    //             },
    //         },
    //     ];
    //     this.popup.showAlert("Warning", { text: "toast.no-internet-connection" }, buttons);
    // }
}
