import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root",
})
export class ImageProcessorService {
    public fourSidedShapeCrop(url: string): Promise<Info> {
        return new Promise((resolve) => {
            const img = new Image();

            img.onload = () => {
                const { naturalWidth, naturalHeight } = img;
                const canvas = document.createElement("canvas");

                const ctx = canvas.getContext("2d")!;
                if (naturalWidth >= naturalHeight) {
                    canvas.width = naturalHeight;
                    canvas.height = naturalHeight;
                    ctx.drawImage(
                        img,
                        (naturalWidth - naturalHeight) / 2,
                        0,
                        naturalHeight,
                        naturalHeight,
                        0,
                        0,
                        naturalHeight,
                        naturalHeight,
                    );
                } else {
                    canvas.width = naturalWidth;
                    canvas.height = naturalWidth;
                    ctx.drawImage(
                        img,
                        0,
                        (naturalHeight - naturalWidth) / 2,
                        naturalWidth,
                        naturalWidth,
                        0,
                        0,
                        naturalWidth,
                        naturalWidth,
                    );
                }

                resolve({
                    width: canvas.width,
                    height: canvas.height,
                    dataUrl: canvas.toDataURL(),
                    isDataUrl: true,
                });
            };
            img.src = url;
        });
    }

    public circularCrop(url: string): Promise<Info> {
        return new Promise((resolve) => {
            const canvas = document.createElement("canvas");
            const img = new Image();
            img.onload = () => {
                const { naturalWidth, naturalHeight } = img;
                const size = Math.min(naturalWidth, naturalHeight);

                // update the canvas size
                canvas.width = size;
                canvas.height = size;
                // draw image to canvas
                const ctx = canvas.getContext("2d")!;

                ctx.drawImage(img, 0, 0);

                // only draw image where mask is
                ctx.globalCompositeOperation = "destination-in";
                // draw our circle mask
                ctx.fillStyle = "#000";
                ctx.beginPath();
                ctx.arc(size / 2, size / 2, size / 2, 0, 2 * Math.PI);
                ctx.fill();

                // restore to default composite operation (is draw over current image)
                ctx.globalCompositeOperation = "source-over";
                resolve({
                    width: canvas.width,
                    height: canvas.height,
                    dataUrl: canvas.toDataURL(),
                    isDataUrl: true,
                });
            };
            img.src = url;
        });
    }

    fitScreen(url: string): Promise<Info> {
        return new Promise<Info>((resolve) => {
            const img = new Image();

            img.onload = () => {
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d")!;

                const { naturalWidth, naturalHeight } = img;

                let scalingFactor;
                let croppedHeight;
                let croppedWidth;

                if (innerWidth >= innerHeight) {
                    scalingFactor = innerWidth / naturalWidth;
                    croppedHeight = innerHeight / scalingFactor;
                    croppedWidth = innerWidth / scalingFactor;
                    canvas.height = croppedHeight;
                    canvas.width = croppedWidth;

                    ctx.drawImage(img, 0, 0, croppedWidth, croppedHeight, 0, 0, croppedWidth, croppedHeight);
                } else {
                    scalingFactor = innerHeight / naturalHeight;
                    canvas.height = innerHeight;
                    canvas.width = innerWidth;

                    ctx.drawImage(
                        img,
                        0,
                        0,
                        canvas.width / scalingFactor,
                        canvas.height / scalingFactor,
                        0,
                        0,
                        canvas.width,
                        canvas.height,
                    );
                }

                resolve({
                    width: canvas.width,
                    height: canvas.height,
                    dataUrl: canvas.toDataURL(),
                    isDataUrl: true,
                });
            };
            img.src = url;
        });
    }

    public dataURItoBlob(dataURI: string): Blob {
        const arr = dataURI.split(",");
        const byteString = atob(arr[1]);
        const mimeString = arr[0].split(":")[1].split(";")[0];

        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ab], { type: mimeString });
    }
}

export class Info {
    width: number;
    height: number;
    dataUrl: string;
    isDataUrl: boolean = false;
}
