import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";

import { PipesModule } from "../../pipes/pipes.module";

import { ActivationElementPageRoutingModule } from "./activation-element-routing.module";
import { ActivationElementPage } from "./activation-element.page";

@NgModule({
    imports: [CommonModule, FormsModule, IonicModule, ActivationElementPageRoutingModule, PipesModule, TranslateModule],
    declarations: [ActivationElementPage],
})
export class ActivationElementPageModule {}
