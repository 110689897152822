import { Util } from "../util/util";

export interface ThemeProperties {
    "--ion-color-primary": string;
    "--ion-color-primary-rgb": string;
    "--ion-color-primary-contrast": string;
    "--ion-color-primary-contrast-rgb": string;
    "--ion-color-primary-shade": string;
    "--ion-color-primary-tint": string;
    "--ion-toolbar-background"?: string;
    "--ion-toolbar-color"?: string;
}

export class Theme {
    color: IColor = {} as IColor;
    contrast: IColor = {} as IColor;
    shade: string = "";
    tint: string = "";
    properties: ThemeProperties = {} as ThemeProperties;

    constructor(color: string) {
        this.color.hex = color;
        this.color.rgb = Util.hexToRgb(this.color.hex);
        this.contrast = this.getCorrectTextColor(this.color.rgb);
        this.shade = this.LightenDarkenColor(this.color.hex, -10);
        this.tint = this.LightenDarkenColor(this.color.hex, 10);
        this.setProperties();
    }

    private LightenDarkenColor(color: string, percent: number) {
        const num = parseInt(color.replace("#", ""), 16),
            amt = Math.round(2.55 * percent),
            R = (num >> 16) + amt,
            B = ((num >> 8) & 0x00ff) + amt,
            G = (num & 0x0000ff) + amt;
        return (
            "#" +
            (
                0x1000000 +
                (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
                (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 +
                (G < 255 ? (G < 1 ? 0 : G) : 255)
            )
                .toString(16)
                .slice(1)
        );
    }

    private getCorrectTextColor(rgb: string): { hex: string; rgb: string } {
        /*
    From this W3C document: http://www.webmasterworld.com/r.cgi?f=88&d=9769&url=http://www.w3.org/TR/AERT#color-contrast

    Color brightness is determined by the following formula:
    ((Red value X 299) + (Green value X 587) + (Blue value X 114)) / 1000

    I know this could be more compact, but I think this is easier to read/explain.

    */
        const threshold = 130; /* about half of 256. Lower threshold equals more dark text on dark background  */
        const hRed = parseInt(rgb[0]);
        const hGreen = parseInt(rgb[1]);
        const hBlue = parseInt(rgb[2]);

        const cBrightness = (hRed * 299 + hGreen * 587 + hBlue * 114) / 1000;
        if (cBrightness > threshold) {
            return { hex: "#000000", rgb: "0,0,0" };
        } else {
            return { hex: "#ffffff", rgb: "255,255,255" };
        }
    }

    private setProperties() {
        this.properties = {
            ["--ion-color-primary"]: this.color.hex,
            ["--ion-color-primary-rgb"]: this.color.rgb,
            ["--ion-color-primary-contrast"]: this.contrast.hex,
            ["--ion-color-primary-contrast-rgb"]: this.contrast.rgb,
            ["--ion-color-primary-shade"]: this.shade,
            ["--ion-color-primary-tint"]: this.tint,
            // ['--ion-toolbar-background']: this.color.hex,
            // ['--ion-toolbar-color']: this.contrast.hex
        };
    }
}

export interface IColor {
    hex: string;
    rgb: string;
}
