import { ItemData } from ".";
import { BaseResponse } from "./protocol/response";

export enum ActivationActions {
    SKIP_CAPTURE = "skip_capture",
    ERROR = "error",
}
export class ProspectTrackingInfo extends BaseResponse {
    info: ItemData[];
    activation_action?: {
        action: ActivationActions;
        message?: string; // will only exist if action === "error"
        activity_id: number;
        prospect_id: number;
    };
    prospect_tracking_info: {
        Email: string;
        goal_prospect_token: string;
        guid: string;
        ipAddress: string;
        prospect_id: number;
    };
    additional_options: {
        session_attendance_status?: boolean;
    };
}
