<ion-content>
    @if (isLoading === null || isLoading === true) {
        <div class="no-data">
            <ion-spinner color="primary"></ion-spinner>
        </div>
    }
    @if (!reload) {
        <iframe
            [src]="activationUrl | safeUrl"
            allow="camera *;microphone *;display-capture *; web-share *"
            allowusermedia
            allowfullscreen
            webkitallowfullscreen
            mozallowfullscreen
            oallowfullscreen
            msallowfullscreen
            width="100%"
            height="100%"
            id="activation"
            (load)="loaded()"
            title="Activation"
        ></iframe>
    }
</ion-content>
