import { Form } from "./form";
import { Station } from "./station";

export class Activation {
    id: number;
    name: string;
    create_date: string;
    modified_date: string;
    background_image: string;
    event: Form;
    is_active: boolean;
    activation_capture_form_after: boolean;
    display_capture_form: number;
    url: string;
    capture_screen_url: string;
    submit_button_background_color: string;
    submit_button_text_color: string;
    submit_button_text: string;
    activation_style: ActivationStyle;
    instructions_content: string;
    instructions_webview_mode: number;
    webview_success_action_type: string;
    webview_success_message: string;
    webview_success_redirect_url: string;
    prospect_info_tracking_url: string;
    activation_mode: string;
    append_prospect_id_to_redirect_url: 0 | 1;
    station: Station;
    append_goal_prospect_token_to_redirect_url: 0 | 1;
    activation_identifier: { is_activation_preload_allowed: boolean };

    public static parseActivation(dbActivation: any, form: Form): Activation {
        const act = new Activation();
        act.id = dbActivation.id;
        act.background_image = dbActivation.background_image;
        act.create_date = dbActivation.create_date;
        act.modified_date = dbActivation.modified_date;
        act.event = form;
        act.name = dbActivation.name;
        act.is_active = dbActivation.is_active;
        act.activation_capture_form_after = dbActivation.activation_capture_form_after;
        act.url = dbActivation.url;
        act.capture_screen_url = dbActivation.capture_screen_url;
        act.display_capture_form = dbActivation.display_capture_form;
        act.submit_button_background_color = dbActivation.submit_button_background_color;
        act.submit_button_text = dbActivation.submit_button_text;
        act.submit_button_text_color = dbActivation.submit_button_text_color;
        act.activation_style = dbActivation.activation_style;
        act.instructions_content = dbActivation.instructions_content;
        act.instructions_webview_mode = dbActivation.instructions_webview_mode;
        act.webview_success_action_type = dbActivation.webview_success_action_type;
        act.webview_success_message = dbActivation.webview_success_message;
        act.webview_success_redirect_url = dbActivation.webview_success_redirect_url;
        act.prospect_info_tracking_url = dbActivation.prospect_info_tracking_url;
        act.append_prospect_id_to_redirect_url = dbActivation.append_prospect_id_to_redirect_url;
        act.station = dbActivation.station;
        act.activation_identifier = dbActivation.activation_identifier;
        return act;
    }
}

export interface ActivationStyle {
    is_enable_screensaver: boolean;
    is_event_screensaver: boolean;
    rotation_period: number;
    screensaver_media_items: string[];
    is_randomize: boolean;
    switch_frequency: number;
    transition_effect: "slide" | "fade" | "flip";
    play_button_size: "small" | "medium" | "large";
}
