export enum PARAMS {
    FORM_ID = "f",
    SUBMISSION_TOKEN = "s",
    STATION_ID = "st",
    MODE = "m",
    EVENT_WEB_ACCESS_TOKEN = "e",
    ACTIVATION_ID = "a",
    LANGUAGE = "l",
    ACTION_BTN_POSITION = "b",
    FORM_TYPE = "form_type", // template | device
    LAUNCHER = "launcher", // event_gen_mobile | event_gen_web,
    SUBMISSION_TYPE = "t", // to detect whether it's a drafted submission >> it's value normal | drafted
    SUBMIT_BUTTON_BOTTOM_PADDING = "sbbp",
}

export enum LAUNCHER_TYPES {
    EVENT_GEN_MOBILE = "event_gen_mobile",
    EVENT_GEN_WEB = "event_gen_web",
}
