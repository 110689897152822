import { inject, Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";

@Injectable({ providedIn: "root" })
export class OldRoutesRedirector implements CanActivate {
    private router = inject(Router);

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
        if (state.url.startsWith("/#/")) {
            const [path, queryParams] = state.url.split("?");
            const t = new URLSearchParams(queryParams);
            const obj = {};
            t.forEach((value, key) => {
                obj[key] = value;
            });
            return this.router.createUrlTree(["/capture" + path.slice(2)], { queryParams: obj });
        } else {
            return true;
        }
    }
}
