import { SupportedFormat } from "@capacitor-community/barcode-scanner";

import { LL_FIELD_ID, LL_FIELD_UNIQUE_IDENTIFIER } from "../constants/transcription-fields";
import { ScanMethod } from "../services/capture/iscan";
import { UserEvent } from "../services/user-events.service";
import { HexColor, Minutes } from "../util/typeAliases";

import { ScannerHardware, ScannerTitles, ScannerType } from "./Scanner";
import { Activation } from "./activation";
import { AgeVerification } from "./age-verification";
import { DateVerification } from "./date-verifications";
import { IDocumentSet } from "./documentSet";
import { QuestionFeeder } from "./question-feeder";
import { ratingLabel } from "./submission-action";
import { LookupElementSettings } from "./lookups";
import { OrderCheckboxSubmissionData, OrderRadioSubmissionData } from "./form-submission";

export enum CheckboxVariants {
    normal = "normal",
    multiSelect = "multi-select",
    rank = "rank",
    orderCheckbox = "order_checkbox",
    orderMultiselect = "order_multiselect",
    orderRank = "order_rank",
}

export enum RadioVariants {
    normal = "normal",
    numericSurvey = "numeric_survey",
    orderRadio = "order_radio",
}

export enum DropdownVariants {
    orderDropdown = "order_dropdown",
}

export enum AudioVariants {
    audit = "audit",
}

type BaseFormElement = {
    id: `element_${number}`;
    parent_element_id: ElementId | "";
    field_error_message: string;
    size: string;
    is_required: boolean;
    is_always_display: boolean;
    is_conditional: boolean;
    is_not_prefilled: boolean;
    is_hidden: boolean;
    is_readonly: boolean;
    is_filled_from_barcode: boolean;
    is_label_visible: boolean;
    type: FormElementType;
    available_in_activations: boolean;
    available_in_event_form: boolean;
    position: number;
    default_value: string;
    total_child: number;
    sub_elements: SubElement[];
    is_used_for_completion_rate: boolean;
    options: Option[];
    mapping: ParentElementMapping[];
    visible_conditions: VisibleCondition[];
    title: string;
    placeholder: string;
    isMatchingRules: boolean;
    is_filled_from_list?: boolean;
    // added it in the base, in case the backend wanted to support another element
    // So, we don't actually know at dev time, what elements will be supported
    lookup?: LookupElementSettings;
};

export type NameElement = Omit<BaseFormElement, "sub_elements"> & {
    type: FormElementType.simple_name;
    mapping: SubElementMapping[];
    sub_elements: [SubElement, SubElement];
};

export type EmailElement = BaseFormElement & {
    type: FormElementType.email;
} & (
        | {
              // will be added if form verifying email is enabled to validate when submitting
              is_enabled_email_verification: true;
              verified_email_message: string;
              verify_email_message: string;
          }
        | {
              is_enabled_email_verification: false;
          }
    );

export type UrlElement = BaseFormElement & {
    type: FormElementType.url;
};

export type TextElement = BaseFormElement & {
    type: FormElementType.text;
};

export type TextareaElement = BaseFormElement & {
    type: FormElementType.textarea;
};

export type AssignOwnerElement = BaseFormElement & {
    type: FormElementType.assign_owner;
    show_in_kiosk: boolean;
};

export type DateElement = BaseFormElement & {
    type: FormElementType.date;
    date_verification: DateVerification;
};

export type TimeElement = BaseFormElement & {
    type: FormElementType.time;
    date_verification: DateVerification;
};

export type DateTimeElement = BaseFormElement & {
    type: FormElementType.datetime;
    date_verification: DateVerification;
};

export type SeparatorElement = BaseFormElement & {
    type: FormElementType.separator;
};

export type AddressElement = Omit<BaseFormElement, "sub_elements"> & {
    type: FormElementType.address;
    mapping: SubElementMapping[];
    sub_elements: [SubElement, SubElement, SubElement, SubElement, SubElement, SubElement];
};

export type MoneyElement = BaseFormElement & {
    type: FormElementType.money;
};

export type NumberElement = BaseFormElement & {
    type: FormElementType.number;
};

export type PhoneElement = BaseFormElement & {
    type: FormElementType.phone;
};

export type SimplePhoneElement = BaseFormElement & {
    type: FormElementType.simple_phone;
};

export type ImageElement = BaseFormElement & {
    type: FormElementType.image;
    variant?: ImageVariants;
    selfie_frame_shape?: "none" | "circle" | "square";
};

export type SignatureElement = BaseFormElement & {
    type: FormElementType.signature;
};

export type MeetingElement = BaseFormElement & {
    type: FormElementType.meeting;
    style: {
        text_color: HexColor;
        background_color: HexColor;
    };
};

type BaseDropDownElement = BaseFormElement & {
    type: FormElementType.select;
    allow_unselecting: boolean;
};

type NormalSelectElement = BaseDropDownElement & {
    variant: "";
};

export type OrderDropDownElement = Omit<BaseDropDownElement, "options"> & {
    variant: DropdownVariants.orderDropdown;
    options: OrderOption[];
    allow_collect_note: boolean;
    allow_collect_quantity: boolean;
};

export type SelectElement = NormalSelectElement | OrderDropDownElement;

type SurveyVariants =
    | { display_variant: DisplayVariants.classic }
    | {
          display_variant: DisplayVariants.survey;
          survey_orientation_display: SurveyOrientation;
          survey_size_display: "normal" | "small";
      };

export enum SurveyOrientation {
    horizontal = "horizontal",
    vertical = "vertical",
}

type BaseRadioStyles = {
    full_width_text: boolean;
    underline: boolean;
    italicize: boolean;
    // used in classic radio
    text_color: HexColor;
};

type BaseRadioElement = BaseFormElement & {
    type: FormElementType.radio;
    allow_unselecting: boolean;
    style: BaseRadioStyles;
};

export type NormalRadioElement = BaseRadioElement & {
    variant: RadioVariants.normal;
    display_variant: DisplayVariants;
    style: BaseRadioStyles & {
        // used in survey radio
        selection_color: HexColor;
    };
} & SurveyVariants;

export type NumericSurveyRadioElement = BaseRadioElement & {
    variant: RadioVariants.numericSurvey;
    right_hint: string;
    left_hint: string;
};

export type OrderRadioElement = Omit<BaseRadioElement, "options"> & {
    variant: RadioVariants.orderRadio;
    options: OrderOption[];
    allow_collect_note: boolean;
    allow_collect_quantity: boolean;
};

export type RadioElement = NormalRadioElement | NumericSurveyRadioElement | OrderRadioElement;

export type RatingElement = BaseFormElement & {
    type: FormElementType.rating;
    labels: ratingLabel[];
    style: {
        text_color: HexColor;
        background_color: HexColor;
    };
};

export type QuestionFeederElement = BaseFormElement & {
    type: FormElementType.engagem_feeder;
    questions_data: QuestionFeeder;
    style: {
        border_color: HexColor;
        question_remove_icon_color: HexColor;
        answers_remove_icon_color: HexColor;
        question_color: HexColor;
        answer_color: HexColor;
        locked_question_color: HexColor;
        right_answer_label: string;
        wrong_answer_label: string;
        question_label: string;
        question_element_labels_color: HexColor;
    };
};

export type SectionElement = BaseFormElement & {
    type: FormElementType.section;
    collapse_content: boolean;
    // will only be available after the user opens the capture page
    children?: FormElement[];
    style: {
        text_color: HexColor;
        background_color: HexColor;
    };
};

export type CustomContentElement = BaseFormElement & {
    type: FormElementType.html_block;
    collapse_content: boolean;
    html_content: string;
};

export type DocumentsElement = BaseFormElement & {
    type: FormElementType.document;
    documents_set: IDocumentSet;
    documents_view_mode: "list" | "grid";
    custom_document_button_text: string;
    style: {
        text_color: HexColor;
        background_color: HexColor;
    };
};

export type CheckboxBase = BaseFormElement & {
    type: FormElementType.checkbox;
    style: {
        underline: boolean;
        italicize: boolean;
    };
    min_selection: number;
    max_selection: number;
};

export type CheckboxClassicSurveyElement = CheckboxBase & {
    variant: CheckboxVariants.multiSelect | CheckboxVariants.normal | CheckboxVariants.rank;
    style: {
        underline: boolean;
        italicize: boolean;
        // used in classic (display_variant) checkbox
        vertical_alignment: "top" | "bottom" | "middle";
        full_width_text: boolean;
    };
} & SurveyVariants;
export type OrderCheckboxElement = Omit<CheckboxBase, "options"> & {
    variant: CheckboxVariants.orderCheckbox | CheckboxVariants.orderMultiselect | CheckboxVariants.orderRank;
    allow_collect_note: boolean;
    allow_collect_quantity: boolean;
    options: OrderOption[];
};

export type CheckboxElement = CheckboxClassicSurveyElement | OrderCheckboxElement;

export type ActivationElement = BaseFormElement & {
    type: FormElementType.activation;
    is_allow_retry_playing_activation: boolean;
    activation: Activation;
    style: {
        text_color: HexColor;
        background_color: HexColor;
    };
};

export type BusinessCardElement = BaseFormElement & {
    type: FormElementType.business_card;
    is_enable_transcription: 0 | 1;
    is_enable_ocr_transcription: 0 | 1;
    is_auto_ocr_transcription: 0 | 1;
    is_scan_cards_and_prefill_form: 0 | 1;
    transcription_expedited_localization: string;
    transcription_expedited_mobile_localizations: string[];
    style: {
        text_color: HexColor;
        background_color: HexColor;
    };
};

export type BarcodeElement = BaseFormElement & {
    type: FormElementType.barcode;
    accept_invalid_barcode: boolean;
    post_show_reconciliation: boolean;
    barcode_provider_id: string;
    barcode_provider_name: string;
    badge_type: ScannerType;
    //specify the barcode format ()
    barcode_type: SupportedFormat;
    webview_default_camera: "front" | "back";
    scan_type: ScannerTitles | ScannerHardware;
    allow_group_scan: boolean;
    age_verification: AgeVerification;
    scan_method: ScanMethod;
    scan_badge_text: string;
    re_scan_badge_text: string;
    style: {
        text_color: HexColor;
        background_color: HexColor;
    };
    // will be true for intelliScan element
    is_intelliscan_enabled: boolean;
};

export type BooleanElement = BaseFormElement & {
    type: FormElementType.boolean;
    description: string;
    display_variant: BooleanVariants;
    reflect_session_attendance_status: boolean;
    style: {
        underline: boolean;
        toggle_active_color: string;
    };
};

export type AudioElement = BaseFormElement & {
    type: FormElementType.audio;
    max_recording_time_minutes: Minutes;
    // for audit (audio and audit aren't seperated as they share same component)
    variant?: AudioVariants;
    auto_recording_triggers?: UserEvent[];
    style: {
        text_color: HexColor;
        background_color: HexColor;
    };
};

export type SpeakerSectionElement = BaseFormElement & {
    type: FormElementType.speaker_section;
    collapse_content: boolean;
    // will only be available after the user opens the capture page
    children?: FormElement[];
    speaker: Speaker;
    style: {
        text_color: HexColor;
        background_color: HexColor;
    };
};

export type Speaker = {
    id: number;
    first_name: string;
    last_name: "Ross";
    email: string;
    job_title: string;
    photo_url: string;
    organization: string;
};

export type SessionSectionElement = BaseFormElement & {
    type: FormElementType.session_section;
    collapse_content: boolean;
    // will only be available after the user opens the capture page
    children?: FormElement[];
    style: {
        text_color: HexColor;
        background_color: HexColor;
    };
};

export type ImagePlaceholderElement = BaseFormElement & {
    type: FormElementType.image_placeholder;
    image_url: string;
    image_display_mode: "cover" | "fit";
};

export type StarRatingElement = BaseFormElement & {
    type: FormElementType.star_rating;
    number_of_stars: number;
    allow_unselecting: boolean;
    style: {
        survey_star_color: HexColor;
    };
};

export interface SubElement {
    sub_element_id: SubElementId;
    hint: string;
    is_required: boolean;
    visible: boolean;
}

export enum DisplayVariants {
    survey = "survey",
    classic = "classic",
}

export type VisibleConditionValue = string[] | number[];

export enum visibleConditionTypes {
    HAS_VALUE = "has_value",
    EQUALS = "equals",
    CONTAINS = "contains",
    DOESNT_CONTAIN = "doesnot_contain",
    IS_BLANK = "is_blank",
    NOT_EQUAL = "not_equal",
    CHECK = "check",
    DOESNOT_CHECK = "doesnot_check",
}

export enum VisibilityRuleType {
    Field = "field",
    Stations = "stations",
    MemberOfList = "memberOfList",
}

export type FieldVisibleCondition = {
    type: VisibilityRuleType.Field;
    operator: "AND" | "OR";
    element_id: ElementId;
    sub_element_id?: SubElementId;
} & (
    | {
          condition:
              | visibleConditionTypes.CHECK
              | visibleConditionTypes.DOESNOT_CHECK
              | visibleConditionTypes.HAS_VALUE
              | visibleConditionTypes.IS_BLANK;
      }
    | {
          condition:
              | visibleConditionTypes.EQUALS
              | visibleConditionTypes.CONTAINS
              | visibleConditionTypes.NOT_EQUAL
              | visibleConditionTypes.DOESNT_CONTAIN;
          value: VisibleConditionValue;
          case_sensitive_match: boolean;
      }
);

export interface StationsVisibleCondition {
    type: VisibilityRuleType.Stations;
    operator: "AND" | "OR"; // always "AND"
    value: number[];
}

export interface MemberOfList {
    type: VisibilityRuleType.MemberOfList;
    operator: "AND" | "OR"; // always "AND"
    is_member_of_list: boolean;
}

export type VisibleCondition = FieldVisibleCondition | StationsVisibleCondition | MemberOfList;

export interface Option {
    option: string;
    option_label: string;
    position: number;
    is_default: number;
}

export interface OrderOption extends Option {
    icon: string | null;
}

export interface ParentElementMapping {
    ll_field_id: LL_FIELD_ID;
    ll_field_unique_identifier: LL_FIELD_UNIQUE_IDENTIFIER;
    ll_field_type: string;
    ll_field_data_type: string;
}

export interface SubElementMapping {
    ll_field_id: LL_FIELD_ID;
    ll_field_unique_identifier: LL_FIELD_UNIQUE_IDENTIFIER;
    ll_field_type: string;
    ll_field_data_type: string;
    sub_element_id: SubElementId;
}

export type ElementMapping = FormElement["mapping"];

// remove the union type only if we add the speaker section element
export type ElementId = FormElement["id"] | `${FormElement["id"]}_${string}_${number}`;
export type SubElementId = `${ElementId}_${number}`;
export type ElementOrSubElementId = ElementId | SubElementId;

export function getEmptyValueByElementType(formElement: OrderCheckboxElement): OrderCheckboxSubmissionData;
export function getEmptyValueByElementType(
    formElement: OrderRadioElement | OrderDropDownElement,
): OrderRadioSubmissionData;
export function getEmptyValueByElementType(
    formElement: CheckboxClassicSurveyElement | DocumentsElement | ImageElement | QuestionFeederElement,
): [];
export function getEmptyValueByElementType(formElement: BusinessCardElement): { front: ""; back: "" };
export function getEmptyValueByElementType(formElement: BooleanElement): false;
export function getEmptyValueByElementType(
    formElement: FormElement,
): "" | [] | false | { front: ""; back: "" } | OrderCheckboxSubmissionData | OrderRadioSubmissionData;

export function getEmptyValueByElementType(
    formElement: FormElement,
): "" | [] | false | { front: ""; back: "" } | OrderCheckboxSubmissionData | OrderRadioSubmissionData {
    if (formElement.type === FormElementType.checkbox) {
        if (
            formElement.variant === CheckboxVariants.orderCheckbox ||
            formElement.variant === CheckboxVariants.orderMultiselect ||
            formElement.variant === CheckboxVariants.orderRank
        ) {
            return {
                values: [],
                note: "",
            };
        }

        return [];
    }
    if (formElement.type === FormElementType.radio) {
        if (formElement.variant === RadioVariants.orderRadio) {
            return {
                value: "",
                quantity: 0,
                note: "",
            };
        }

        return "";
    }
    if (formElement.type === FormElementType.select) {
        if (formElement.variant === DropdownVariants.orderDropdown) {
            return {
                value: "",
                quantity: 0,
                note: "",
            };
        }

        return "";
    }
    if (
        formElement.type === FormElementType.document ||
        formElement.type === FormElementType.image ||
        formElement.type === FormElementType.engagem_feeder
    ) {
        return [];
    }
    if (formElement.type === FormElementType.boolean) {
        return false;
    }

    if (formElement.type === FormElementType.business_card) {
        return { front: "", back: "" };
    }
    return "";
}

export type ElementsTypeMap = {
    [FormElementType.rating]: RatingElement;
    [FormElementType.engagem_feeder]: QuestionFeederElement;
    [FormElementType.boolean]: BooleanElement;
    [FormElementType.assign_owner]: AssignOwnerElement;
    [FormElementType.audio]: AudioElement;
    [FormElementType.meeting]: MeetingElement;
    [FormElementType.date]: DateElement;
    [FormElementType.datetime]: DateTimeElement;
    [FormElementType.document]: DocumentsElement;
    [FormElementType.activation]: ActivationElement;
    [FormElementType.separator]: SeparatorElement;
    [FormElementType.barcode]: BarcodeElement;
    [FormElementType.signature]: SignatureElement;
    [FormElementType.image]: ImageElement;
    [FormElementType.business_card]: BusinessCardElement;
    [FormElementType.email]: EmailElement;
    [FormElementType.section]: SectionElement;
    [FormElementType.html_block]: CustomContentElement;
    [FormElementType.url]: UrlElement;
    [FormElementType.text]: TextElement;
    [FormElementType.select]: SelectElement;
    [FormElementType.radio]: RadioElement;
    [FormElementType.simple_name]: NameElement;
    [FormElementType.textarea]: TextareaElement;
    [FormElementType.time]: TimeElement;
    [FormElementType.address]: AddressElement;
    [FormElementType.money]: MoneyElement;
    [FormElementType.number]: NumberElement;
    [FormElementType.checkbox]: CheckboxElement;
    [FormElementType.phone]: PhoneElement;
    [FormElementType.simple_phone]: SimplePhoneElement;
    [FormElementType.speaker_section]: SpeakerSectionElement;
    [FormElementType.session_section]: SessionSectionElement;
    [FormElementType.image_placeholder]: ImagePlaceholderElement;
    [FormElementType.star_rating]: StarRatingElement;
};

export enum FormElementType {
    email = "email",
    section = "section_block",
    html_block = "section",
    url = "url",
    text = "text",
    select = "select",
    radio = "radio",
    simple_name = "simple_name",
    textarea = "textarea",
    time = "time",
    address = "address",
    money = "money",
    number = "number",
    date = "date",
    phone = "phone",
    simple_phone = "simple_phone",
    checkbox = "checkbox",
    image = "image",
    business_card = "business_card",
    signature = "signature",
    barcode = "barcode",
    separator = "column_separator",
    activation = "activation",
    document = "documents",
    datetime = "datetime",
    meeting = "meeting",
    audio = "audio",
    rating = "rating",
    assign_owner = "assign_owner",
    boolean = "boolean",
    engagem_feeder = "engagem_feeder",
    speaker_section = "speaker_section_block",
    session_section = "session_section_block",
    image_placeholder = "image_placeholder",
    star_rating = "star_survey",
}

export type FormElement =
    | NameElement
    | AudioElement
    | SectionElement
    | CustomContentElement
    | DocumentsElement
    | CheckboxElement
    | ActivationElement
    | BusinessCardElement
    | BarcodeElement
    | DateElement
    | BooleanElement
    | RatingElement
    | QuestionFeederElement
    | EmailElement
    | UrlElement
    | TextElement
    | TextareaElement
    | AssignOwnerElement
    | TimeElement
    | DateTimeElement
    | SeparatorElement
    | AddressElement
    | MoneyElement
    | NumberElement
    | PhoneElement
    | ImageElement
    | SignatureElement
    | MeetingElement
    | SelectElement
    | RadioElement
    | SimplePhoneElement
    | SpeakerSectionElement
    | SessionSectionElement
    | ImagePlaceholderElement
    | StarRatingElement;

export enum ImageVariants {
    selfie = "selfie",
}

export enum BooleanVariants {
    checkbox = "checkbox",
    toggle = "toggle",
}

export const FORM_ELEMENT_MEDIA = [
    FormElementType.business_card,
    FormElementType.image,
    FormElementType.signature,
    FormElementType.audio,
];

export function isOrderMultiSelectElement(element: FormElement): element is OrderCheckboxElement {
    return (
        element.type === FormElementType.checkbox &&
        [CheckboxVariants.orderCheckbox, CheckboxVariants.orderMultiselect, CheckboxVariants.orderRank].includes(
            element.variant,
        )
    );
}

export function isOrderSingleSelectElement(element: FormElement): element is OrderRadioElement | OrderDropDownElement {
    return (
        (element.type === FormElementType.radio && element.variant === RadioVariants.orderRadio) ||
        (element.type === FormElementType.select && element.variant === DropdownVariants.orderDropdown)
    );
}
