import { Component, inject, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";

import { Station, VisitedStation } from "../../model";

@Component({
    selector: "app-stations",
    templateUrl: "./stations.page.html",
    styleUrls: ["./stations.page.scss"],
})
export class StationsPage implements OnInit {
    @Input() stations: Station[];
    @Input() selectedStation: Station;
    @Input() visitedStations: VisitedStation[];
    @Input() disableStationSelection: boolean = false;
    @Input() showCancel: boolean = false;
    isSingleTap = true;
    filteredStations: Station[];

    public modalCtrl = inject(ModalController);

    ngOnInit(): void {
        this.filteredStations = this.stations;
    }

    onCancel(): void {
        this.modalCtrl.dismiss({ isCancel: true });
    }

    onStationChoose(e: any): void {
        this.selectedStation = e.target.value;
        if (!this.isSingleTap) return;
        this.modalCtrl.dismiss({ isCancel: false, station: this.selectedStation });
    }

    done(): void {
        this.modalCtrl.dismiss({ isCancel: false, station: this.selectedStation });
    }

    getItems(event: any): void {
        const val = event.target.value || "";
        this.filteredStations = this.stations.filter((station) =>
            station.name.toLowerCase().includes(val.toLowerCase()),
        );
    }
}
