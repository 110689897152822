import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";

import { ComponentModule } from "../../components/component.module";
import { DirectivesModule } from "../../directives/directives.module";
import { PipesModule } from "../../pipes/pipes.module";
import { SessionInfoComponent } from "../session-info/session-info.component";
import { SpeakerInfoComponent } from "../speaker-info/speaker-info.component";
import { StarRatingComponent } from "../star-rating/star-rating.component";

import { DynamicFormElementComponent } from "./dynamic-form-element/dynamic-form-element.component";
import { ActivationGameElementComponent } from "./elements/activation-game-element/activation-game-element.component";
import { AddressComponent } from "./elements/address/address.component";
import { AudioComponent } from "./elements/audio/audio.component";
import { BadgeComponent } from "./elements/badge/badge.component";
import { BooleanComponent } from "./elements/boolean/boolean";
import { BusinessCardComponent } from "./elements/business-card/business-card.component";
import { CheckboxesComponent } from "./elements/checkboxes/checkboxes.component";
import { DateTimeComponent } from "./elements/date-time/date-time.component";
import { DateComponent } from "./elements/date/date.component";
import { DocumentComponent } from "./elements/document/document.component";
import { DropdownComponent } from "./elements/dropdown/dropdown.component";
import { HtmlBlockComponent } from "./elements/html-block/html-block.component";
import { ImagePlaceholderComponent } from "./elements/image-placeholder/image-placeholder.component";
import { ImageComponent } from "./elements/image/image.component";
import { MeetingComponent } from "./elements/meeting/meeting.component";
import { QuestionFeederComponent } from "./elements/question-feeder/question-feeder.component";
import { QuestionComponent } from "./elements/question-feeder/question/question.component";
import { RadiosComponent } from "./elements/radios/radios.component";
import { RatingComponent } from "./elements/rating/rating.component";
import { SelfieComponent } from "./elements/selfie/selfie.component";
import { SignatureComponent } from "./elements/signature/signature.component";
import { SimpleNameComponent } from "./elements/simple-name/simple-name.component";
import { TimeComponent } from "./elements/time/time.component";
import { FormViewComponent } from "./form-view.component";
import { OrderCheckboxesComponent } from "./elements/order-checkboxes/order-checkboxes.component";
import { OrderRadioComponent } from "./elements/order-radio/order-radio.component";
import { OrderMultiselectComponent } from "./elements/order-multiselect/order-multiselect.component";
import { OrderRankComponent } from "./elements/order-rank/order-rank.component";
import { OrderDropdownComponent } from "./elements/order-dropdown/order-dropdown.component";
import { EmailComponent } from "./elements/email/email.component";

@NgModule({
    declarations: [
        FormViewComponent,
        DynamicFormElementComponent,
        AddressComponent,
        AudioComponent,
        BadgeComponent,
        CheckboxesComponent,
        DocumentComponent,
        DropdownComponent,
        HtmlBlockComponent,
        ImageComponent,
        RadiosComponent,
        SignatureComponent,
        SimpleNameComponent,
        ActivationGameElementComponent,
        DateComponent,
        DateTimeComponent,
        TimeComponent,
        MeetingComponent,
        RatingComponent,
        BooleanComponent,
        QuestionFeederComponent,
        QuestionComponent,
        BusinessCardComponent,
        SelfieComponent,
        SpeakerInfoComponent,
        SessionInfoComponent,
        ImagePlaceholderComponent,
        StarRatingComponent,
        OrderCheckboxesComponent,
        OrderRadioComponent,
        OrderMultiselectComponent,
        OrderRankComponent,
        OrderDropdownComponent,
        EmailComponent,
    ],
    exports: [FormViewComponent],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        IonicModule,
        TranslateModule.forChild(),
        PipesModule,
        ComponentModule,
        DirectivesModule,
    ],
})
export class FormViewModule {}
