import _isObject from "lodash-es/isObject";
import { OrderCheckboxSubmissionData, OrderRadioSubmissionData } from "../model";

export function isArrayOfString(obj: unknown): obj is string[] {
    return Array.isArray(obj) && obj.every((el) => typeof el === "string");
}

export function isArrayOfNumbers(obj: unknown): obj is number[] {
    return Array.isArray(obj) && obj.every((el) => Number.isInteger(el));
}

export function isBusinessCardObject(obj: unknown): obj is { front?: string; back?: string } {
    if (!_isObject(obj)) {
        return false;
    }
    const objectKeys = Object.keys(obj);
    return objectKeys.includes("front") || objectKeys.includes("back");
}

export function isArrayOfObjects(obj: any): obj is Record<any, any>[] {
    return Array.isArray(obj) && obj.every((el) => _isObject(el));
}

export function isOrderCheckboxObject(obj: any): obj is OrderCheckboxSubmissionData {
    if (!_isObject(obj)) {
        return false;
    }
    return Object.hasOwn(obj, "values") && Object.hasOwn(obj, "note");
}

export function isOrderRadioObject(obj: any): obj is OrderRadioSubmissionData {
    if (!_isObject(obj)) {
        return false;
    }
    return Object.hasOwn(obj, "value") && Object.hasOwn(obj, "quantity") && Object.hasOwn(obj, "note");
}
