import { Component, Input } from "@angular/core";
import { Color } from "@ionic/core";

import { SubmitResponseAction, SubmitResponseActionInfo } from "../../model/submitFormResponse";

@Component({
    selector: "app-html-message-page",
    templateUrl: "./html-message.page.html",
    styleUrls: ["./html-message.page.scss"],
})
export class HtmlMessagePage {
    @Input() message: string = "";
    @Input() color: Color = "dark";
    @Input() actions: SubmitResponseActionInfo[] = [];

    takeAction(action: SubmitResponseAction): void {
        if (action === SubmitResponseAction.SHOW_START_OVER_BUTTON) {
            location.reload();
        }
    }
}
