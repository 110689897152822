import _cloneDeep from "lodash-es/cloneDeep";
import _get from "lodash-es/get";

import { TRANSCRIPTION_FIELDS } from "../constants/transcription-fields";
import { Util } from "../util/util";

import { UserWithAccessToForm } from "./admin/UserWithAccessToForm";
import { Form, getIdByUniqueElementName } from "./form";
import { CheckboxVariants, ElementOrSubElementId, FormElement, FormElementType } from "./form-element";
import { SubmissionDataResponse, SubmissionResponse } from "./protocol/submission-response";
import { SubmissionQuestionData } from "./question-feeder";
import { VisitedStation } from "./station";
import { RatingValues, SubmissionActionData } from "./submission-action";
import { BusinessCardValue } from "../components/form-view/elements/business-card/business-card.component";

export type OrderCheckboxSubmissionData = { values: { value: string; quantity: number }[]; note: string };
export type OrderRadioSubmissionData = { value: string; quantity: number; note: string };

export class FormSubmission {
    id: number = 0;
    form_id: number = 0;
    status: SubmissionStatus = SubmissionStatus.None;
    prospect_id: number = 0;
    email: string = "";
    company: string = "";
    jobTitle: string = "";
    phone: string = "";
    first_name: string = "";
    last_name: string = "";
    full_name: string = "";
    activity_id: number = 0;
    hold_request_id: number = 0;
    hold_submission: number = 0;
    hold_submission_reason: string = "";
    invalid_fields: 0 | 1 | "" = "";
    data: {
        [key: ElementOrSubElementId]:
            | string
            | string[]
            // documents
            | number[]
            // engagem
            | SubmissionQuestionData[]
            // business card
            | Partial<BusinessCardValue>
            //boolean element
            | boolean
            | OrderCheckboxSubmissionData
            | OrderRadioSubmissionData;
    } = {};
    submission_date: string = new Date().toISOString();
    last_sync_date: string = "";
    hidden_elements: string[] = [];
    is_filled_from_list: 0 | 1 = 0;
    is_rapid_scan: 0 | 1 = 0;
    captured_by_user_name: string = "";
    captured_by_user_id: number = 0;
    submission_type: FormSubmissionType = FormSubmissionType.eventWebView;
    stations: VisitedStation[] = [];
    location: UserLocation = "";
    barcodeID: string = "";
    activation_id: number = 0;
    update_date: string = "";
    submit_message?: string = "";
    prospect_picture: string = "";
    submission_actions: SubmissionActionData = {};
    station_id: number | "" = "";
    station_touch: boolean = false;
    prospect: { id?: number; owner?: UserWithAccessToForm } = {};
    assign_owner_user: UserWithAccessToForm | null;
    prospect_tracking_info: {
        Email: string;
        goal_prospect_token: string;
        guid: string;
        ipAddress: string;
        prospect_id: number;
    } = {} as any;
    submission_token: string = "";
    query_parameters?: Record<string, string>;
    is_valid_for_goal: boolean = true;
    send_to_capture_portal: boolean = false;
    conversion_rate: number | null = null;
    submission_data_sales_price: number = 0;
    influenced_pipeline_value_correction: number = 0;

    public static getInstance(data: any): FormSubmission {
        const sub = new FormSubmission();
        if (!data) return sub;

        sub.id = parseInt(data.id) || data.id;
        sub.form_id = data.form_id;
        sub.status = data.status;
        sub.prospect_id = data.prospect_id || null;
        sub.email = data.email;
        sub.company = data.company;
        sub.phone = data.phone;
        sub.jobTitle = data.jobTitle;
        sub.first_name = data.first_name;
        sub.last_name = data.last_name;
        sub.full_name = data.full_name;
        sub.activation_id = data.activation_id || null;
        sub.activity_id = data.activity_id || null;
        sub.hold_request_id = data.hold_request_id || null;
        sub.hold_submission = data.hold_submission || null;
        sub.hold_submission_reason = data.hold_submission_reason;
        sub.invalid_fields = data.invalid_fields;
        sub.data = data.data;
        sub.submission_date = data.submission_date;
        sub.submission_type = data.submission_type;
        sub.last_sync_date = data.last_sync_date;
        sub.hidden_elements = data.hidden_elements;
        sub.is_filled_from_list = data.is_filled_from_list;
        sub.is_rapid_scan = data.is_rapid_scan;
        sub.captured_by_user_id = data.captured_by_user_id;
        sub.captured_by_user_name = data.captured_by_user_name;
        sub.barcodeID = data.barcodeID;
        sub.stations = data.stations;
        sub.location = data.location;
        sub.activation_id = data.activation_id;
        sub.update_date = data.update_date;
        sub.submit_message = data.submit_message;
        sub.prospect_picture = data.prospect_picture;
        sub.submission_actions = data.submission_actions;
        sub.station_id = data.station_id;
        sub.station_touch = data.station_touch;
        sub.submission_actions.rating =
            sub.submission_actions.rating === null ? RatingValues.NONE : sub.submission_actions.rating;
        sub.prospect = data.prospect;
        sub.assign_owner_user = data.assign_owner_user;

        if (sub?.assign_owner_user) {
            sub.assign_owner_user.fullName = sub.assign_owner_user.firstname + " " + sub.assign_owner_user.lastname;
        }
        if (sub.prospect?.owner) {
            sub.prospect.owner.fullName = sub.prospect.owner.firstname + " " + sub.prospect.owner.lastname;
        }
        sub.prospect_tracking_info = data.prospect_tracking_info;
        sub.send_to_capture_portal = data.send_to_capture_portal;
        sub.conversion_rate = data.conversion_rate;
        sub.submission_data_sales_price = data.submission_data_sales_price;
        sub.influenced_pipeline_value_correction = data.influenced_pipeline_value_correction;

        return sub;
    }

    public updateFields(form: Form): void {
        form.elements.forEach((element) => {
            switch (element.type) {
                case FormElementType.simple_name: {
                    this.first_name = (this.data[element.id + "_1"] as string) || "";
                    this.last_name = (this.data[element.id + "_2"] as string) || "";
                    this.full_name = Util.getFullName(this.first_name, this.last_name);
                    break;
                }
                case FormElementType.email:
                    this.email = (this.data[element.id] as string) || this.email || "";
                    break;
            }
        });
        let id = getIdByUniqueElementName("WorkPhone", form.elements);
        if (id) {
            this.phone = (this.data[id] as any) || "";
        }
        id = getIdByUniqueElementName("Company", form.elements);
        if (id) {
            this.company = (this.data[id] as any) || "";
        }
        id = getIdByUniqueElementName("JobTitle", form.elements);
        if (id) {
            this.jobTitle = (this.data[id] as any) || "";
        }
    }

    public getMappedFromResponse(item: SubmissionResponse, form: Form): void {
        this.id = item.device_submission_id || item.activity_id;
        this.data = reshapeSubmissionDataReturnedFromApi(item.data, form);
        this.form_id = form.form_id;
        this.updateFields(form);
    }

    public getElementsThatMapsToTranscriptionFields(form: Form): FormElement[] {
        return form.elements.filter((element) => {
            return element.mapping.some((e) => TRANSCRIPTION_FIELDS[e.ll_field_id]);
        });
    }

    public clone(): FormSubmission {
        return _cloneDeep(this);
    }
}

export function reshapeSubmissionDataReturnedFromApi(
    data: SubmissionDataResponse[],
    form: Form,
): FormSubmission["data"] {
    let reshapedData = {};
    data.forEach((dataItem) => {
        if (!dataItem.value) {
            return;
        }
        const elementId = dataItem.element_id;
        const element = form.getElementById(elementId);

        if (!element) return;

        switch (element.type) {
            case FormElementType.simple_name:
            case FormElementType.address:
                if (dataItem.value_splitted) {
                    const values = dataItem.value_splitted;
                    reshapedData = { ...reshapedData, ...values };
                }
                break;
            case FormElementType.document:
            case FormElementType.image:
                try {
                    reshapedData[elementId] = JSON.parse(dataItem.value);
                } catch (e) {
                    reshapedData[elementId] = [];
                }
                break;
            case FormElementType.business_card:
                try {
                    const data = JSON.parse(dataItem.value);
                    reshapedData[elementId] = {
                        front: _get(data, "front", ""),
                        back: _get(data, "back", ""),
                    };
                } catch (e) {
                    reshapedData[elementId] = {
                        front: "",
                        back: "",
                    };
                }
                break;
            case FormElementType.audio:
                reshapedData[elementId] = dataItem.value.replace(/\\/g, "");
                break;
            case FormElementType.checkbox:
                if (
                    element.variant === CheckboxVariants.orderCheckbox ||
                    element.variant === CheckboxVariants.orderRank ||
                    element.variant === CheckboxVariants.orderMultiselect
                ) {
                    reshapedData[elementId] = dataItem.value;
                } else {
                    reshapedData[elementId] = dataItem.value.split(";");
                }
                break;
            case FormElementType.boolean:
                reshapedData[elementId] = dataItem.value === "true";
                break;
            default:
                reshapedData[elementId] = dataItem.value;
        }
    });
    return reshapedData;
}

export enum SubmissionStatus {
    None = 0, // initial status when creating submission
}

// backend already uppercase them all, but they should always be lowercase in mobile
export enum FormSubmissionType {
    // normal = "normal",
    barcode = "barcode",
    list = "list",
    transcription = "transcription",
    activation = "activation",
    ocr = "ocr_transcription",
    eventWebView = "event_webview",
    lookup = "lookup",
}

export type UserLocation =
    | {
          coords: {
              altitude: number;
              heading: number;
              latitude: number;
              accuracy: number;
              altitudeAccuracy: number;
              speed: number;
              longitude: number;
              timestamp: number;
          };
      }
    | "";
