import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { RedirectActivationToNewUrl } from "./guards/RedirectActivationToNewUrl";
import { RedirectEventToNewUrl } from "./guards/RedirectEventToNewUrl";
import { SetLanguage } from "./guards/SetLanguage";
import { SetToken } from "./guards/SetToken";
import { OldRoutesRedirector } from "./guards/oldRoutesRedirector";
import { ActivationResolver } from "./resolvers/activationResolver";
import { FormResolver } from "./resolvers/formResolver";
import { SubmissionsResolver } from "./resolvers/submissionResolver";

// support old routes
//   For new submissions
//   #/event/5566/37261e6e41d3398ccb66fddeb4543084/submit
//   #/event/5566/37261e6e41d3398ccb66fddeb4543084/submit-station:1278
const routes: Routes = [
    // // *****  FOR TESTING  ******
    // {
    //     path: "submissions",
    //     // loadChildren: () => import("./pages/test/submissions/submissions.module").then((m) => m.SubmissionsPageModule),
    //     canActivate: [SetToken, SetLanguage],
    //     resolve: {
    //         form: FormResolver,
    //     },
    //     component: SubmissionsPage,
    // },
    // **************************
    {
        path: "capture/submission",
        canActivate: [SetToken, SetLanguage],
        resolve: {
            form: FormResolver,
            submission: SubmissionsResolver,
        },
        loadChildren: () => import("./pages/capture/capture.module").then((m) => m.CaptureModule),
    },
    {
        path: "capture/event",
        children: [
            {
                path: "**",
                canActivate: [RedirectEventToNewUrl],
                loadChildren: () => import("./pages/capture/capture.module").then((m) => m.CaptureModule),
            },
        ],
    },
    {
        path: "capture/activation",
        children: [
            {
                path: "",
                pathMatch: "full",
                canActivate: [SetToken],
                resolve: {
                    activation: ActivationResolver,
                    submission: SubmissionsResolver,
                },
                loadChildren: () =>
                    import("./pages/capture-activation/capture-activation.module").then(
                        (m) => m.CaptureActivationModule,
                    ),
            },
            {
                path: "**",
                canActivate: [RedirectActivationToNewUrl],
                loadChildren: () =>
                    import("./pages/capture-activation/capture-activation.module").then(
                        (m) => m.CaptureActivationModule,
                    ),
            },
        ],
    },
    {
        path: "screen-saver",
        loadChildren: () => import("./pages/screen-saver/screen-saver.module").then((m) => m.ScreenSaverModule),
    },
    {
        path: "form-instructions",
        loadChildren: () =>
            import("./pages/form-instructions/form-instructions.module").then((m) => m.FormInstructionsPageModule),
    },

    {
        path: "stations",
        loadChildren: () => import("./pages/stations/stations.module").then((m) => m.StationsPageModule),
    },

    {
        path: "search",
        loadChildren: () => import("./pages/search/search.module").then((m) => m.SearchPageModule),
    },

    {
        path: "activation-element",
        loadChildren: () =>
            import("./pages/activation-element/activation-element.module").then((m) => m.ActivationElementPageModule),
    },
    {
        path: "scanner-settings",
        loadChildren: () =>
            import("./pages/scanner-settings/scanner-settings.module").then((m) => m.ScannerSettingsPageModule),
    },

    {
        path: "documents",
        loadChildren: () => import("./pages/documents/documents.module").then((m) => m.DocumentsPageModule),
    },
    {
        path: "signature",
        loadChildren: () => import("./pages/signature/signature.module").then((m) => m.SignaturePageModule),
    },
    {
        path: "goc-camera",
        loadChildren: () => import("./pages/goc-camera/goc-camera.module").then((m) => m.GocCameraPageModule),
    },
    {
        path: "image-preview",
        loadChildren: () => import("./pages/image-preview/image-preview.module").then((m) => m.ImagePreviewPageModule),
    },
    {
        path: "game",
        loadChildren: () => import("./pages/game/game.module").then((m) => m.GamePageModule),
    },
    {
        path: "goc-barcode-scanner",
        loadChildren: () =>
            import("./pages/goc-barcode-scanner/goc-barcode-scanner.module").then((m) => m.GocBarcodeScannerPageModule),
    },

    {
        path: "meeting-automation",
        loadChildren: () =>
            import("./pages/meeting-automation/meeting-automation.module").then((m) => m.MeetingAutomationModule),
    },
    {
        path: "submission-actions",
        loadChildren: () =>
            import("./pages/submission-actions/submission-actions.module").then((m) => m.SubmissionActionsModule),
    },

    {
        path: "nfcscan",
        loadChildren: () => import("./pages/nfcscan/nfcscan.module").then((m) => m.NFCscanPageModule),
    },
    {
        path: "iframe-container",
        loadChildren: () =>
            import("./pages/iframe-container/iframe-container.module").then((m) => m.IframeContainerPageModule),
    },
    {
        path: "users-selection",
        loadChildren: () =>
            import("./pages/users-selection/users-selection.module").then((m) => m.UsersSelectionPageModule),
    },
    {
        path: "success",
        loadChildren: () => import("./pages/html-message/html-message.module").then((m) => m.HtmlMessagePageModule),
    },
    {
        path: "html5-qrcode",
        loadChildren: () => import("./pages/html5-qrcode/html5-qrcode.module").then((m) => m.Html5QrcodePageModule),
    },
    {
        path: "lookups-verifications",
        loadChildren: () =>
            import("./pages/lookups-verifications/lookups-verifications.module").then(
                (m) => m.LookupsVerificationsPageModule,
            ),
    },
    {
        path: "**",
        canActivate: [OldRoutesRedirector],
        loadChildren: () => import("./pages/not-found/not-found.module").then((m) => m.NotFoundPageModule),
    },
    {
        path: "handheld-scanner",
        loadChildren: () =>
            import("./pages/handheld-scanner/handheld-scanner.module").then((m) => m.HandheldScannerPageModule),
    },
    {
        path: "selfie-camera",
        loadChildren: () => import("./pages/selfie-camera/selfie-camera.module").then((m) => m.SelfieCameraPageModule),
    },
    {
        path: "lookups-verifications",
        loadChildren: () =>
            import("./pages/lookups-verifications/lookups-verifications.module").then(
                (m) => m.LookupsVerificationsPageModule,
            ),
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { urlUpdateStrategy: "deferred" })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
