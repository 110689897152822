import { inject, Injectable } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { Geolocation } from "@ionic-native/geolocation/ngx";
import { MenuController, ModalController } from "@ionic/angular";
import _merge from "lodash-es/merge";
import { Subject, timer } from "rxjs";

import { LAUNCHER_TYPES, PARAMS } from "../constants/ParamsNames";
import { ToastTheme } from "../constants/toast-theme";
import {
    Activation,
    ActivationResultType,
    ActivationStyle,
    ElementId,
    ElementOrSubElementId,
    EventStyle,
    Form,
    FormElementType,
    FormMode,
    FormSubmission,
    FormSubmissionType,
    getIdByUniqueElementName,
    ItemData,
    MeetingElement,
    Station,
    UserLocation,
} from "../model";
import { DateVerification } from "../model/date-verifications";
import { OUTBOUND_MESSAGES } from "../model/messages";
import { WebviewSuccessActionsTypes } from "../model/mobile-success-actions";
import { ActivationActions, ProspectTrackingInfo } from "../model/prospect-tracking-info";
import { SubmissionQuestionData } from "../model/question-feeder";
import { SubmissionActionTypes } from "../model/submission-action";
import { SubmitFormResponse, SubmitResponseActionInfo } from "../model/submitFormResponse";
import { FormInstructionsPage } from "../pages/form-instructions/form-instructions.page";
import { HtmlMessagePage } from "../pages/html-message/html-message.page";
import { StationsPage } from "../pages/stations/stations.page";
import { Util } from "../util/util";
import { PopupService } from "./popup.service";
import { ScreenSaverService } from "./screen-saver.service";
import { SubmissionService } from "./submissions/submission.service";
import { UserFeedbackService } from "./user-feedback.service";
import { UseInMode } from "../model/form-fill-actions";

@Injectable()
export class FormViewService {
    public modal = inject(ModalController);
    public popup = inject(PopupService);
    public screenSaverService = inject(ScreenSaverService);
    private menuCtrl = inject(MenuController);
    private userFeedback = inject(UserFeedbackService);
    private geoLocation = inject(Geolocation);
    private activatedRoute = inject(ActivatedRoute);
    private submissionService = inject(SubmissionService);
    form: Form;
    submission: FormSubmission;
    activation: Activation;
    isRapidScanMode: boolean = false;
    prospect: ProspectTrackingInfo;
    disableStations: boolean;
    loaded: boolean = false;
    activationResult: ActivationResultType | undefined;
    isProcessing: boolean;
    isFormHasChanges: boolean;
    displayIframeOrder: number;
    handleSubmitParams: () => void;

    selectedStation: Station;
    $selectedStation = new Subject<Station>();
    $shouldFormUpdate = new Subject<boolean>();

    verifiedEmails = new Map<ElementId, boolean>();

    get isViewMode(): boolean {
        return this.activatedRoute.snapshot.queryParamMap.get(PARAMS.MODE) === FormMode.view;
    }

    get isEditMode(): boolean {
        return this.activatedRoute.snapshot.queryParamMap.get(PARAMS.MODE) === FormMode.edit;
    }

    get isNewCapture(): boolean {
        const mode = this.activatedRoute.snapshot.queryParamMap.get(PARAMS.MODE);
        return mode === FormMode.submit || mode === FormMode.preview;
    }

    // move to submit service
    public async submitForm(theForm: UntypedFormGroup): Promise<void> {
        this.handleDateVerifications(theForm, () => {
            this._handleSubmitParams();
            this.doSave();
        });
    }

    // /move to submit service
    public _handleSubmitParams(submission = this.submission): void {
        if (!submission.id) submission.id = new Date().getTime();

        submission.hidden_elements = this.form.getHiddenElements(this.isActivation());
        if (this.selectedStation) submission.station_id = this.selectedStation.id;
        submission.submission_type = this.getSubmissionType();
        submission.location = Util.safeParse(localStorage.getItem("location"));

        submission.submission_date = new Date().toISOString(); // ask in case of update
        if (this.isEditMode) submission.update_date = new Date().toISOString();
        submission.form_id = this.form.form_id;

        if (this.prospect?.prospect_tracking_info) {
            submission.prospect_tracking_info = this.prospect.prospect_tracking_info;
        }

        if (submission.activity_id) {
            submission.submission_token = this.activatedRoute.snapshot.queryParamMap.get(PARAMS.SUBMISSION_TOKEN);
        }
        submission.updateFields(this.form);
    }

    // move to submit service
    private getSubmissionType(submission = this.submission) {
        // handle this condition from activation submit service
        if (this.isActivation()) return FormSubmissionType.activation;
        else if (submission.submission_type === FormSubmissionType.ocr) {
            return FormSubmissionType.ocr;
        } else if (submission.submission_type === FormSubmissionType.lookup) {
            return FormSubmissionType.lookup;
        } else if (submission.barcodeID) {
            return FormSubmissionType.barcode;
        } else if (submission.prospect_id) {
            return FormSubmissionType.list;
        } else if (this.form.isTranscriptionEnabled() && this.isBusinessCardAdded(submission)) {
            return FormSubmissionType.transcription;
            //workaround
        } else {
            return submission.submission_type;
        }
    }

    // move to submit service
    isBusinessCardAdded(submission = this.submission): boolean {
        const fields = submission.data;
        const businessCardEl = this.form.getElementByType(FormElementType.business_card);
        if (!businessCardEl) return false;

        const businessCard = fields[businessCardEl.id];

        if (businessCard) {
            const front = businessCard["front"];
            const back = businessCard["back"];

            return !!(front || back);
        }

        return false;
    }

    setProcessing(val: boolean): void {
        this.isProcessing = val;
        if (val) this.screenSaverService.stopScreenSaver();
        else this.screenSaverService.startScreenSaver();
    }

    // move to activation submit service
    public setActivationResult(data?: ActivationResultType): void {
        this.activationResult = data;
    }

    // move to activation submit service
    public didUserEnterGame(): boolean {
        return !!(this.activationResult && Object.keys(this.activationResult).length > 0);
    }

    public removeEmptyAnswersFromQuestionFeederAnswers(submission: FormSubmission, form: Form): void {
        const engagemElementId = form.getIdByElementType(FormElementType.engagem_feeder);
        if (!engagemElementId) {
            return;
        }
        const questions = submission.data[engagemElementId] as SubmissionQuestionData[];
        // remove any empty values and sync the correct answer index
        for (const question of questions) {
            const correctAnswerValue = question.answers[question.correct_answer];
            question.answers = question.answers.filter(Boolean);

            const correctAnswerIndex = question.answers.indexOf(correctAnswerValue);
            question.correct_answer = correctAnswerIndex !== -1 ? correctAnswerIndex : "";
        }
    }

    // move to submit service
    private async doSave() {
        this.submission.updateFields(this.form);
        this.isFormHasChanges = false;
        if (!(await this.handleSubmitAction())) return;
        this.setProcessing(true);

        if (this.isEmbedded()) {
            await this.submissionService.postEmbeddedForm(this.submission, this.form);
        } else {
            const response = await this.submissionService.actuallySubmitForm(this.submission, this.form);
            // sometimes we will have actions on the submission that overrides the actions on the form.

            if (response?.response_status == 200) {
                // if the email is from certain domains in the form builder, the response may have a redirect url
                if (response.redirect_url) {
                    Util.processUrlThenRedirect(response.redirect_url);

                    // for the survey and quiz settings
                } else if (response.submission_action_type === "redirect") {
                    Util.processUrlThenRedirect(response.submission_action_redirect_url);
                } else if (response.submission_action_type === "message" && response.submission_action_message) {
                    await this.openSuccessPage(response.submission_action_message, response.additional_actions);
                } else if (this.isEditMode) {
                    this.onSubmissionUpdate(response);
                } else {
                    this.onSubmissionSave(response);
                }
            } else {
                // handle invalid response
                this.popup.showToast({ text: response.message, params: {} }, "top", ToastTheme.Error);
            }
        }

        this.setProcessing(false);
    }

    private isEmbedded(): boolean {
        // if it's embedded in event gen mobile or event gen web. emit a message and don't show the toast
        const launcher = this.activatedRoute.snapshot.queryParamMap.get(PARAMS.LAUNCHER);

        return launcher === LAUNCHER_TYPES.EVENT_GEN_MOBILE || launcher === LAUNCHER_TYPES.EVENT_GEN_WEB;
    }

    private async onSubmissionSave(response: SubmitFormResponse) {
        this.popup.showToast({ text: response.message, params: {} }, "top", ToastTheme.Success);
        await this.handleSuccessAction(response);
        if (this.form.is_mobile_kiosk_mode || this.form.is_mobile_quick_capture_mode) {
            this.setSubmission();
        }
    }

    private async onSubmissionUpdate(response: SubmitFormResponse) {
        this.popup.showToast({ text: response.message, params: {} }, "top", ToastTheme.Success);
        // this.isEditing = false;
        // this.readOnly = true;
        // this.$shouldFormUpdate.next(true);
        // location.reload();
    }

    // move to submit service
    private async handleSubmitAction() {
        // can be combined ....

        // don't show any submission actions in new capture
        if (this.isNewCapture) {
            return true;
        }
        if (this.form.is_mobile_kiosk_mode || !this.form.submission_actions.length) {
            return true;
        }

        if (this.form.getElementByType(FormElementType.rating)) {
            return true;
        }

        if (!this.form.getSubmissionActionForType(SubmissionActionTypes.RATING)) {
            return true;
        }

        return this.takeSubmissionAction();
    }

    // move to submit service
    private async takeSubmissionAction() {
        const module = await import("../pages/submission-actions/submission-actions.page");

        const m = await this.modal.create({
            component: module.SubmissionActionsPage,
            componentProps: {
                actions: this.form.submission_actions,
                actionsValue: this.submission.submission_actions,
                isActivation: this.isActivation(),
                isEditMode: this.isEditMode,
                form: this.form,
                // added later
                submission: this.submission,
            },
            swipeToClose: false, // because we need to prompt a msg when the user clicks cancel
            presentingElement: document.getElementById("main-content"),
        });
        await m.present();
        const actions = (await m.onDidDismiss())?.data?.value;
        if (actions == null) return false;
        this.submission.submission_actions = { ...this.submission.submission_actions, ...actions };
        return true;
    }

    async handleSuccessAction(response: SubmitFormResponse): Promise<void> {
        switch (this.form.webview_success_action_type) {
            case WebviewSuccessActionsTypes.Meeting:
                this.openMeetingAutomation(null, false);
                break;
            case WebviewSuccessActionsTypes.StartOver:
                timer(750).subscribe(() => {
                    location.reload();
                });
                break;
            case WebviewSuccessActionsTypes.Redirect: {
                let url = this.form.webview_success_redirect_url || "";
                if (url.includes("/leaderboard/index.html")) {
                    url = url + "&pid=" + response.prospect_id;
                }
                Util.processUrlThenRedirect(url);
                break;
            }
            case WebviewSuccessActionsTypes.Message:
                await this.openSuccessPage(this.form.webview_success_message);
                break;
            default:
                break;
        }
    }

    async openSuccessPage(message: string, actions?: SubmitResponseActionInfo[]): Promise<void> {
        const modal = await this.modal.create({
            component: HtmlMessagePage,
            componentProps: {
                message,
                actions,
            },
            swipeToClose: false,
            cssClass: "full-screen",
            animated: false,
            backdropDismiss: false,
            presentingElement: document.getElementById("main-content"),
        });
        modal.present();
    }

    async openMeetingAutomation(element?: MeetingElement, showClose = true): Promise<void> {
        this.handleSubmitParams();
        const module = await import("../pages/meeting-automation/meeting-automation.page");
        const modal = await this.modal.create({
            component: module.MeetingAutomationPage,
            componentProps: {
                element: element,
                form: this.form,
                submission: this.submission,
                activation: this.activation,
                showClose: showClose,
            },
            swipeToClose: false,
            showBackdrop: true,
            backdropDismiss: false,
            presentingElement: document.getElementById("main-content"),
        });
        await modal.present();
        await modal.onDidDismiss();
    }

    async setForm(
        data: {
            form: Form;
            submission?: FormSubmission;
            isRapidScanMode?: boolean;
            stationId?: string;
            prospect?: ProspectTrackingInfo;
        },
        submitFn: () => void,
        stopLoading = true,
    ): Promise<void> {
        if (!data.form) return;
        this.form = Object.assign(new Form(), data.form);
        this.setSubmission(data.submission);
        this.isRapidScanMode = !!data.isRapidScanMode;
        //
        this.userFeedback.isRapidScanMode = !!data.isRapidScanMode;
        this.userFeedback.isKioskMode = !!data.form.is_mobile_kiosk_mode;
        //
        this.handleSubmitParams = submitFn;
        data.form.setTheme();
        this.prospect = data.prospect;
        this.load(data.stationId);
        if (stopLoading) {
            this.loaded = true;
            Util.customPostMessage(JSON.stringify({ type: OUTBOUND_MESSAGES.FORM_LOAD_COMPLETE }), "*");
        }
    }

    reInitFormAndSubmission(partialSubmission: Partial<FormSubmission>): void {
        this.form = this.form.clone();
        let submission = this.submission.clone();
        submission = _merge(submission, partialSubmission);
        this.setSubmission(submission);
    }

    // move to activation submit service
    setActivation(
        data: {
            form: Form;
            activation: Activation;
            prospect?: ProspectTrackingInfo;
            stationId?: string;
            submission?: FormSubmission;
        },
        submitFn: () => void,
        stopLoading: boolean,
    ): void {
        if (!data.activation) return;
        this.activation = data.activation;
        data.form.is_mobile_kiosk_mode = true; // enforce kiosk mode in case of activation
        this.setForm(data, submitFn, stopLoading);
        this.displayIframeOrder = this.getCaptureScreenUrlOrder();
    }

    // move to submit service
    public setSubmission(data?: FormSubmission): void {
        if (!data) {
            this.setNewSubmission();
            this.verifiedEmails.clear();
        } else {
            this.submission = data;
            this.setStation(this.submission);
            this.updateVerifiedSubmissions(data.data);
        }
    }

    updateVerifiedSubmissions(data: FormSubmission["data"]): void {
        const emailEls = this.form.getElementsByType(FormElementType.email);
        emailEls.forEach((el) => {
            if (el.is_enabled_email_verification && data[el.id]) {
                this.verifiedEmails.set(el.id, true);
            }
        });
    }

    // move to submit service
    private setNewSubmission() {
        Util.elementScroll(`form-${this.form.form_id}`, { behavior: "smooth", block: "start" });
        this.submission = new FormSubmission();
        this.submission.form_id = this.form.form_id;
        this.submission.activation_id = this.activation ? this.activation.id : 0;
    }

    // move to
    private setStation(submission: FormSubmission) {
        if (submission && submission.station_id) {
            const station = this.form.getStationById(submission.station_id);
            if (station) this.setAndPropagateStation(station);
        }
    }

    // move to activation view service
    getCaptureScreenUrlOrder(): -1 | 1 | 2 {
        switch (this.activation.display_capture_form) {
            case 2:
                return 2;
            case 1:
                return 1;
            default:
                return -1;
        }
    }

    async initScreenSaverMode(): Promise<void> {
        if (!this.isRapidScanMode) {
            this.handleScreenSaverMode();
        }
    }

    private handleScreenSaverMode(): void {
        const screensaverData: EventStyle | ActivationStyle = this.isActivation()
            ? this.handleSSActivation()
            : this.form.event_style;
        this.screenSaverService.init(screensaverData);
    }

    // move to activation view service
    private handleSSActivation(): EventStyle | ActivationStyle {
        if (
            this.activation.activation_style.is_event_screensaver &&
            this.activation.activation_style.is_enable_screensaver
        )
            return this.form.event_style;
        return this.activation.activation_style;
    }

    init(): void {
        this.menuCtrl.enable(false).then();
        // this.routerOutlet.swipeGesture = false;
    }

    async load(stationId: string): Promise<void> {
        // this.loaded = true;
        this.getSavedLocation();

        await this.checkInstructions();
        const station = this.form.getStationById(stationId);
        if (station) {
            this.setAndPropagateStation(station);
        } else {
            if (!(await this.checkStations())) return;
        }
        this.actionsAfterStations();
    }

    reset(): void {
        this.userFeedback.isKioskMode = false;
        this.userFeedback.isRapidScanMode = false;
        this.screenSaverService.stopScreenSaver();
        this.menuCtrl.enable(true).then();
        // this.routerOutlet.swipeGesture = true;
    }

    // refactor
    public async checkInstructions(): Promise<void> {
        if (this.prospect?.activation_action?.action === ActivationActions.SKIP_CAPTURE) return;

        let instructions;
        let getInstructions;
        let shouldShowInstruction;
        if (this.activation) {
            if (this.activation.instructions_webview_mode == 2) {
                shouldShowInstruction = true;
            }
        } else {
            instructions = localStorage["FormInstructions"];
            getInstructions = instructions ? JSON.parse(instructions) : [];
            shouldShowInstruction =
                !this.submission.id &&
                this.form &&
                this.form.is_instructions_webview &&
                getInstructions.indexOf(this.form.id) == -1;
        }
        if (shouldShowInstruction) await this.openInstructions(getInstructions);
    }

    async checkStations(): Promise<boolean | void> {
        if (this.activation) return true;
        if (!this.form.event_stations.length) return true;
        if (this.isNewCapture) {
            const result = await this.openStations();
            if (result) this.handleStationsInteraction();
            return result;
        }
        return true;
    }

    // disable only in kiosk mode
    private handleStationsInteraction() {
        if (this.form.is_mobile_kiosk_mode) this.disableStations = true;
    }

    // refactor
    public async openInstructions(formsInstructions: any[]): Promise<void> {
        if (!this[this.activation ? "activation" : "form"].instructions_content) return;
        const data = {
            instructionName: this[this.activation ? "activation" : "form"].name,
            instructionsContent: this[this.activation ? "activation" : "form"].instructions_content,
        };
        const instructionsModal = await this.modal.create({
            component: FormInstructionsPage,
            componentProps: data,
            // cssClass: "full-screen",
        });
        instructionsModal.present().then(() => {
            if (this.activation && formsInstructions) {
                formsInstructions.push(this.activation.id);
                localStorage.setItem("activationInstructions", JSON.stringify(formsInstructions));
            } else if (!this.activation) {
                formsInstructions.push(this.form.id);
                localStorage.setItem("FormInstructions", JSON.stringify(formsInstructions));
            }
        });

        await instructionsModal.onDidDismiss();
    }

    async openStations(viewMode = false): Promise<boolean | void> {
        if (this.disableStations) return;
        const m = await this.modal.create({
            component: StationsPage,
            componentProps: {
                stations: this.form.event_stations,
                selectedStation: this.selectedStation,
                visitedStations: this.submission.stations,
                showCancel: !viewMode,
                disableStationSelection: viewMode,
            },
            swipeToClose: false,
            backdropDismiss: false,
            showBackdrop: true,
            presentingElement: document.getElementById("main-content"),
        });
        await m.present();
        return this.onStationDismiss((await m.onWillDismiss()).data);
    }

    private async onStationDismiss(data: any) {
        if (!data || data.isCancel) {
            return false;
        } else {
            this.setAndPropagateStation(data.station);
            this.submission.station_touch = true;
            return true;
        }
    }

    private actionsAfterStations() {
        this.initScreenSaverMode();
    }

    // remove this
    public isActivation(): boolean {
        return !!this.activation;
    }

    // move to submit service
    handleDateVerifications(formGroup: UntypedFormGroup, handler: () => void): void {
        const els = [
            ...this.form.getElementsByType(FormElementType.datetime),
            ...this.form.getElementsByType(FormElementType.date),
        ];
        let f = false;
        for (let index = 0; index < els.length; index++) {
            const dateEl = els[index];
            const date = formGroup.controls[dateEl.id].value;
            dateEl.date_verification = Object.assign(new DateVerification(), dateEl.date_verification);
            if (dateEl.date_verification.is_enable_date_verification && date) {
                dateEl.date_verification.setVerificationAttrs(date);
                const btns = dateEl.date_verification.getAlertBtns(handler, () => {});
                const msg = dateEl.date_verification.getAlertMsg();
                if (msg) {
                    this.popup.showPrompt({ text: msg.title }, { text: msg.msg }, [], btns);
                    f = false;
                    return;
                } else f = true;
            } else if (index == els.length - 1) f = true;
        }
        if (!els.length || f) handler();
    }

    setAndPropagateStation(station: Station): void {
        // due to legacy code and high risk factor. keep them separated
        // instead of using this.$selectedStation.value
        this.selectedStation = station;
        this.$selectedStation.next(station);
    }

    // we can ask the user for permission, and when submitting we can call getCurrentPosition
    // to get the position again, without having to store anything in localStorage
    private async getSavedLocation() {
        localStorage.setItem("location", "");
        if (!this.form?.is_enable_webview_location) return;

        try {
            const { coords, timestamp } = await this.geoLocation.getCurrentPosition({
                enableHighAccuracy: true,
                timeout: 5000,
            });
            const location: UserLocation = { coords: { ...coords, timestamp } };
            localStorage.setItem("location", JSON.stringify(location));
        } catch (e) {
            //
        }
    }

    fillFormWithSubmissionAndInfo(formGroup: UntypedFormGroup, submission?: FormSubmission, info?: ItemData[]): void {
        if (submission && info) {
            const pairs = this.form.getSubmissionDataArrayFromItemsData(info);
            const subData = {};
            pairs.forEach((pair) => {
                subData[pair.id] = pair.value;
            });
            submission.data = { ...subData, ...submission.data };
            this.setSubmission(submission);
        }

        if (submission) {
            this.fillFormWithSubmission(submission);
        }

        if (info) {
            this.fillFormWithInfo(formGroup, info);
        }
    }

    fillFormWithSubmission(submission: FormSubmission): void {
        const newSubmission = new FormSubmission();
        // this is intentional to ignore any submission root data and only use the data.data
        newSubmission.data = submission.data;

        this.setSubmission(newSubmission);
    }

    fillFormWithInfo(formGroup: UntypedFormGroup, data: ItemData[]): void {
        const pairs: { id: ElementOrSubElementId; value: string; type: FormElementType }[] = [];

        for (const itemData of data) {
            const elementId = getIdByUniqueElementName(itemData.ll_field_unique_identifier, this.form.elements);
            if (!elementId) {
                continue;
            }
            const parentId = Util.getParentId(elementId);
            const parentElement = this.form.getElementById(parentId);

            pairs.push({
                id: elementId,
                value: itemData.value,
                type: parentElement.type,
            });
        }

        Form.fillFormGroupData(pairs, formGroup);
    }

    filterFormFillActions(form: Form, currentUseInMode: UseInMode): void {
        if (!form) return;
        form.form_fill_actions.forEach((ffa) => {
            const actions = ffa.actions.filter((action) => {
                return action.useIn === currentUseInMode || action.useIn === UseInMode.BOTH;
            });
            ffa.actions = actions;
        });
    }
}
