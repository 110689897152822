import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import * as SentryAngular from "@sentry/angular-ivy";
import * as Sentry from "@sentry/capacitor";
import { BrowserTracing } from "@sentry/tracing";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

(window as any).global = window;

/**
 * Prevents Angular change detection from
 * running with certain Web Component callbacks
 */
(window as any).__Zone_disable_customElements = true;

Sentry.init(
    {
        dsn: "https://ff629621dc334c24bebe7803bfc81787@o1148737.ingest.sentry.io/6605482",
        environment: environment.NAME,
        tracesSampleRate: 0.7,
        integrations: [new BrowserTracing()],
        enabled: environment.production,
        ignoreErrors: [
            /Non-Error exception captured/gi,
            /not implemented/gi,
            /not available/gi,
            /cannot set keyboard style/gi,
            /ExpressionChangedAfterItHasBeenCheckedError/gi,
        ],
    },
    SentryAngular.init,
);

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.log(err));
