import { HexColor } from "../util/typeAliases";

import { ElementId, VisibleCondition } from "./form-element";
import { Sounds } from "./sounds";

export enum FormFillActionType {
    VIBRATE = "VIBRATE",
    PLAY_SOUND = "PLAY_SOUND",
    NOTIFY_OTHER_PEOPLE = "NOTIFY_USERS",
    MESSAGE = "SHOW_MESSAGE",
    BLOCK_SUBMISSION = "BLOCK_SUBMISSION",
    UPDATE_FIELD = "UPDATE_FIELD",
    CLEAR_FIELD = "CLEAR_FIELD",
    SEND_TO_CAPTURE_PORTAL = "SEND_TO_CAPTURE_PORTAL",
    SET_CONVERSION_RATE = "SET_CONVERSION_RATE",
    DECREMENT_INFLUENCED_PIPELINE = "DECREMENT_INFLUENCED_PIPELINE",
    INCREMENT_INFLUENCED_PIPELINE = "INCREMENT_INFLUENCED_PIPELINE",
}

export interface VibrateAction extends BaseAction {
    type: FormFillActionType.VIBRATE;
    vibrationStyle: "light" | "medium" | "heavy";
}

export interface PlaySoundAction extends BaseAction {
    type: FormFillActionType.PLAY_SOUND;
    playSound: Sounds;
}

export interface NotifyOtherPeopleAction extends BaseAction {
    type: FormFillActionType.NOTIFY_OTHER_PEOPLE;
}

export interface MessageAction extends BaseAction {
    type: FormFillActionType.MESSAGE;
    message: string;
    textColor: HexColor;
    backgroundColor: HexColor;
}

export interface UpdateFieldAction extends BaseAction {
    type: FormFillActionType.UPDATE_FIELD;
    element_id: ElementId;
    update_field_value: string;
    override_if_not_empty: boolean;
}

export interface ClearFieldAction extends BaseAction {
    type: FormFillActionType.CLEAR_FIELD;
    element_id: ElementId;
}

export interface BlockSubmissionAction extends BaseAction {
    type: FormFillActionType.BLOCK_SUBMISSION;
    message: string;
}

export interface SendToCapturePortalAction extends BaseAction {
    type: FormFillActionType.SEND_TO_CAPTURE_PORTAL;
}

export interface SetConversionRateAction extends BaseAction {
    type: FormFillActionType.SET_CONVERSION_RATE;
    value: number;
}

export interface IncrementSalesPriceAction extends BaseAction {
    type: FormFillActionType.INCREMENT_INFLUENCED_PIPELINE;
    value: number;
}

export interface DecrementSalesPriceAction extends BaseAction {
    type: FormFillActionType.DECREMENT_INFLUENCED_PIPELINE;
    value: number;
}

export enum ActionModes {
    KIOSK_MODE = "kioskMode",
    NORMAL_CAPTURE = "normalCapture",
    BOTH = "both",
}

export enum UseInMode {
    EVENT_FORM = "eventForm",
    ACTIVATION_FORM = "activationForm",
    BOTH = "both",
}

export interface BaseAction {
    type: FormFillActionType;
    readonly useIn: UseInMode;
}

export type Action =
    | VibrateAction
    | PlaySoundAction
    | NotifyOtherPeopleAction
    | MessageAction
    | UpdateFieldAction
    | BlockSubmissionAction
    | ClearFieldAction
    | SendToCapturePortalAction
    | SetConversionRateAction
    | IncrementSalesPriceAction
    | DecrementSalesPriceAction;

export interface FormFillAction {
    readonly id: number;
    readonly conditions: VisibleCondition[];
    actions: Action[];
}

export type FormFillActionTypeMap = {
    [FormFillActionType.BLOCK_SUBMISSION]: BlockSubmissionAction;
    [FormFillActionType.CLEAR_FIELD]: ClearFieldAction;
    [FormFillActionType.UPDATE_FIELD]: UpdateFieldAction;
    [FormFillActionType.MESSAGE]: MessageAction;
    [FormFillActionType.VIBRATE]: VibrateAction;
    [FormFillActionType.PLAY_SOUND]: PlaySoundAction;
    [FormFillActionType.NOTIFY_OTHER_PEOPLE]: NotifyOtherPeopleAction;
    [FormFillActionType.SEND_TO_CAPTURE_PORTAL]: SendToCapturePortalAction;
    [FormFillActionType.SET_CONVERSION_RATE]: SetConversionRateAction;
    [FormFillActionType.INCREMENT_INFLUENCED_PIPELINE]: IncrementSalesPriceAction;
    [FormFillActionType.DECREMENT_INFLUENCED_PIPELINE]: DecrementSalesPriceAction;
};
