export enum Sounds {
    ELEGANT = "ELEGANT",
    OPEND_ENDED = "OPEN_ENDED",
    JUNTOS = "JUNTOS",
    DEDUCTION = "DEDUCTION",
    EVENTUALLY = "EVENTUALLY",
    PIECE_OF_CAKE = "PIECE_OF_CAKE",
    PRISTINE = "PRISTINE",
    SUCCEEDED = "SUCCEEDED",
    WHEN = "WHEN",
}

export const SoundsSourceMap = {
    [Sounds.ELEGANT]: "assets/tones/elegant.mp3",
    [Sounds.OPEND_ENDED]: "assets/tones/open-ended.mp3",
    [Sounds.JUNTOS]: "assets/tones/juntos.mp3",
    [Sounds.DEDUCTION]: "assets/tones/deduction.mp3",
    [Sounds.EVENTUALLY]: "assets/tones/eventually.mp3",
    [Sounds.PIECE_OF_CAKE]: "assets/tones/piece-of-cake.mp3",
    [Sounds.PRISTINE]: "assets/tones/pristine.mp3",
    [Sounds.SUCCEEDED]: "assets/tones/succeeded.mp3",
    [Sounds.WHEN]: "assets/tones/when.mp3",
};
