import { inject, Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";

import { PARAMS } from "../constants/ParamsNames";
import { Form } from "../model";
import { ApiService } from "../services/api.service";

type T = Form;

// type T = string;

@Injectable({ providedIn: "root" })
export class FormResolver {
    private api = inject(ApiService);

    resolve(snapshot: ActivatedRouteSnapshot): Observable<T> {
        const parameter = snapshot.queryParamMap.get(PARAMS.FORM_TYPE);
        const qs = snapshot.queryParams;
        const formType = parameter === "template" ? parameter : "device";
        return this.api.getFormByCurrentToken(formType, qs).pipe(
            catchError(() => {
                return of(null);
            }),
        );
    }
}
