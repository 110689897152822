export const TRANSCRIPTION_FIELDS = {
    "9": "WorkPhone",
    "10": "MobilePhone",
    "12": "Fax",
    "16": "FirstName",
    "17": "LastName",
    "18": "FullName",
    "21": "Email",
    "22": "Company",
    "24": "Website",
    "26": "Country",
    "27": "City",
    "28": "State",
    "29": "StreetAddress",
    "30": "FullAddress",
    "31": "Zipcode",
    "48": "JobTitle",
    "93": "StreetAddress2",
    "119": "MiddleName",
    "120": "AddressJurisdictionCode",
    "121": "IDNumber",
    "122": "HeightInches",
    "123": "WeightPounds",
    "124": "EyeColor",
    "125": "WeightKilograms",
    "126": "HairColor",
    "127": "IDExpiration",
    "128": "DateofBirth",
    "129": "Gender",
    "130": "IDIssueDate",
    "131": "AKAGivenName",
    "132": "AKAFamilyName",
    "133": "AKASuffixName",
    "134": "DLJurisdictionRestrictions",
    "135": "DLJurisdictionEndorsement",
    "136": "WeightRange",
    "137": "DocumentDiscriminator",
    "138": "IDCountry",
    "139": "PlaceofBirth",
    "140": "IDAuditInformation",
    "141": "IDInventoryControlNumber",
    "142": "Race",
    "143": "DLVehicleClassification",
    "144": "DLEndorsementCode",
    "145": "NameSuffix",
    "146": "DLRestrictionCode",
    "147": "DLJurisdictionVehicleClassification",
    "148": "DLJurisdictionEndorsementCode",
    "149": "IDComplianceType",
    "150": "IDRevisionDate",
    "151": "DLHAZMATExpirationDate",
    "152": "IDLimitedDurationIndicator",
    "153": "IDFamilyNameTruncation",
    "154": "IDFirstNameTruncation",
    "155": "IDMiddleNameTruncation",
    "156": "Under18Until",
    "157": "Under19Until",
    "158": "Under21Until",
    "159": "IDOrganDonor",
    "160": "IDVeteran",
    "161": "DLJurisdictionVehicleClass",
    "162": "DLJurisdictionRestrictionCode",
} as const;

type TranscriptionFieldsType = typeof TRANSCRIPTION_FIELDS;
export type LL_FIELD_ID = keyof TranscriptionFieldsType;
export type LL_FIELD_UNIQUE_IDENTIFIER = TranscriptionFieldsType[keyof TranscriptionFieldsType];
