import { NgModule } from "@angular/core";

import { AddElementInvalidClassDirective } from "./add-element-invalid-class.directive";

@NgModule({
    declarations: [AddElementInvalidClassDirective],
    imports: [],
    exports: [AddElementInvalidClassDirective],
})
export class DirectivesModule {}
