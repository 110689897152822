<ion-header>
    <ion-toolbar>
        <ion-buttons slot="start">
            @if (isModal) {
                <ion-button color="primary" icon-only (click)="onDismiss()">
                    <ion-icon name="close"></ion-icon>
                </ion-button>
                <ion-title class="aligned-title"> {{ instructionName }}</ion-title>
            }
            @if (!isModal) {
                <ion-back-button color="white-only" [text]="instructionName"></ion-back-button>
            }
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
    <div class="html-block" [innerHtml]="instructionsContent | safeHtml"></div>
</ion-content>
