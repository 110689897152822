export type Country = { value: string; name: string; aliases?: string[] };
export const Countries: Country[] = [
    {
        value: "United States",
        name: "United States",
        aliases: ["US", "USA", "UNITED STATES OF AMERICA", "U.S.", "U.S.A"],
    },
    { value: "Afghanistan", name: "Afghanistan", aliases: ["AFG"] },
    { value: "Albania", name: "Albania", aliases: ["ALB"] },
    { value: "Algeria", name: "Algeria", aliases: ["DZA"] },
    { value: "Andorra", name: "Andorra", aliases: ["AND"] },
    { value: "Antigua and Barbuda", name: "Antigua and Barbuda", aliases: ["ATG"] },
    { value: "Argentina", name: "Argentina", aliases: ["ARG"] },
    { value: "Armenia", name: "Armenia", aliases: ["ARM"] },
    { value: "Australia", name: "Australia", aliases: ["AUS"] },
    { value: "Austria", name: "Austria", aliases: ["AUT"] },
    { value: "Azerbaijan", name: "Azerbaijan", aliases: ["AZE"] },
    { value: "Bahamas", name: "Bahamas", aliases: ["BHS"] },
    { value: "Bahrain", name: "Bahrain", aliases: ["BHR"] },
    { value: "Bangladesh", name: "Bangladesh", aliases: ["BGD"] },
    { value: "Barbados", name: "Barbados", aliases: ["BRB"] },
    { value: "Belarus", name: "Belarus", aliases: ["BLR"] },
    { value: "Belgium", name: "Belgium", aliases: ["BEL", "BE"] },
    { value: "Belize", name: "Belize", aliases: ["BLZ"] },
    { value: "Benin", name: "Benin", aliases: ["BEN"] },
    { value: "Bhutan", name: "Bhutan", aliases: ["BTN"] },
    { value: "Bolivia", name: "Bolivia" },
    { value: "Bosnia and Herzegovina", name: "Bosnia and Herzegovina", aliases: ["BIH"] },
    { value: "Botswana", name: "Botswana", aliases: ["BWA"] },
    { value: "Brazil", name: "Brazil", aliases: ["BRA"] },
    { value: "Brunei", name: "Brunei" },
    { value: "Bulgaria", name: "Bulgaria", aliases: ["BGR"] },
    { value: "Burkina Faso", name: "Burkina Faso", aliases: ["BFA"] },
    { value: "Burundi", name: "Burundi", aliases: ["BDI"] },
    { value: "Cambodia", name: "Cambodia", aliases: ["KHM"] },
    { value: "Cameroon", name: "Cameroon", aliases: ["CMR"] },
    { value: "Canada", name: "Canada", aliases: ["CAN"] },
    { value: "Cape Verde", name: "Cape Verde", aliases: ["CPV"] },
    { value: "Central African Republic", name: "Central African Republic", aliases: ["CAF"] },
    { value: "Chad", name: "Chad", aliases: ["TCD"] },
    { value: "Chile", name: "Chile", aliases: ["CHL"] },
    { value: "China", name: "China", aliases: ["CHN", "CN"] },
    { value: "Colombia", name: "Colombia", aliases: ["COL"] },
    { value: "Comoros", name: "Comoros", aliases: ["COM"] },
    { value: "Congo", name: "Congo", aliases: ["COG"] },
    { value: "Costa Rica", name: "Costa Rica", aliases: ["CRI"] },
    { value: "Côte d'Ivoire", name: "Côte d'Ivoire", aliases: ["CIV"] },
    { value: "Croatia", name: "Croatia", aliases: ["HRV"] },
    { value: "Cuba", name: "Cuba", aliases: ["CUB"] },
    { value: "Cyprus", name: "Cyprus", aliases: ["CYP"] },
    { value: "Czech Republic", name: "Czech Republic", aliases: ["CZE"] },
    { value: "Denmark", name: "Denmark", aliases: ["DNK"] },
    { value: "Djibouti", name: "Djibouti", aliases: ["DJI"] },
    { value: "Dominica", name: "Dominica", aliases: ["DMA"] },
    { value: "Dominican Republic", name: "Dominican Republic", aliases: ["DOM"] },
    { value: "East Timor", name: "East Timor" },
    { value: "Ecuador", name: "Ecuador", aliases: ["ECU"] },
    { value: "Egypt", name: "Egypt", aliases: ["EGY"] },
    { value: "El Salvador", name: "El Salvador", aliases: ["SLV"] },
    { value: "Equatorial Guinea", name: "Equatorial Guinea", aliases: ["GNQ"] },
    { value: "Eritrea", name: "Eritrea", aliases: ["ERI"] },
    { value: "Estonia", name: "Estonia", aliases: ["EST"] },
    { value: "Ethiopia", name: "Ethiopia", aliases: ["ETH"] },
    { value: "Fiji", name: "Fiji", aliases: ["FJI"] },
    { value: "Finland", name: "Finland", aliases: ["FIN"] },
    { value: "France", name: "France", aliases: ["FR", "FRA", "FRA"] },
    { value: "Gabon", name: "Gabon", aliases: ["GAB"] },
    { value: "Gambia", name: "Gambia", aliases: ["GMB"] },
    { value: "Georgia", name: "Georgia", aliases: ["GEO"] },
    { value: "Germany", name: "Germany", aliases: ["DEU", "DE"] },
    { value: "Ghana", name: "Ghana", aliases: ["GHA"] },
    { value: "Greece", name: "Greece", aliases: ["GRC"] },
    { value: "Grenada", name: "Grenada", aliases: ["GRD"] },
    { value: "Guatemala", name: "Guatemala", aliases: ["GTM"] },
    { value: "Guinea", name: "Guinea", aliases: ["GIN"] },
    { value: "Guinea-Bissau", name: "Guinea-Bissau", aliases: ["GNB"] },
    { value: "Guyana", name: "Guyana", aliases: ["GUY"] },
    { value: "Haiti", name: "Haiti", aliases: ["HTI"] },
    { value: "Honduras", name: "Honduras", aliases: ["HND"] },
    { value: "Hong Kong", name: "Hong Kong", aliases: ["HKG"] },
    { value: "Hungary", name: "Hungary", aliases: ["HUN"] },
    { value: "Iceland", name: "Iceland", aliases: ["ISL"] },
    { value: "India", name: "India", aliases: ["IND"] },
    { value: "Indonesia", name: "Indonesia", aliases: ["IDN"] },
    { value: "Iran", name: "Iran" },
    { value: "Iraq", name: "Iraq", aliases: ["IRQ"] },
    { value: "Ireland", name: "Ireland", aliases: ["IRL"] },
    { value: "Israel", name: "Israel", aliases: ["ISR"] },
    { value: "Italy", name: "Italy", aliases: ["IT", "ITA"] },
    { value: "Jamaica", name: "Jamaica", aliases: ["JAM"] },
    { value: "Japan", name: "Japan", aliases: ["JPN", "JP"] },
    { value: "Jordan", name: "Jordan", aliases: ["JOR"] },
    { value: "Kazakhstan", name: "Kazakhstan", aliases: ["KAZ"] },
    { value: "Kenya", name: "Kenya", aliases: ["KEN"] },
    { value: "Kiribati", name: "Kiribati", aliases: ["KIR"] },
    { value: "North Korea", name: "North Korea" },
    { value: "South Korea", name: "South Korea" },
    { value: "Kuwait", name: "Kuwait", aliases: ["KWT"] },
    { value: "Kyrgyzstan", name: "Kyrgyzstan", aliases: ["KGZ"] },
    { value: "Laos", name: "Laos" },
    { value: "Latvia", name: "Latvia", aliases: ["LVA"] },
    { value: "Lebanon", name: "Lebanon", aliases: ["LBN"] },
    { value: "Lesotho", name: "Lesotho", aliases: ["LSO"] },
    { value: "Liberia", name: "Liberia", aliases: ["LBR"] },
    { value: "Libya", name: "Libya", aliases: ["LBY"] },
    { value: "Liechtenstein", name: "Liechtenstein", aliases: ["LIE"] },
    { value: "Lithuania", name: "Lithuania", aliases: ["LTU"] },
    { value: "Luxembourg", name: "Luxembourg", aliases: ["LUX"] },
    { value: "Macedonia", name: "Macedonia" },
    { value: "Madagascar", name: "Madagascar", aliases: ["MDG"] },
    { value: "Malawi", name: "Malawi", aliases: ["MWI"] },
    { value: "Malaysia", name: "Malaysia", aliases: ["MYS"] },
    { value: "Maldives", name: "Maldives", aliases: ["MDV"] },
    { value: "Mali", name: "Mali", aliases: ["MLI"] },
    { value: "Malta", name: "Malta", aliases: ["MLT"] },
    { value: "Marshall Islands", name: "Marshall Islands", aliases: ["MHL"] },
    { value: "Mauritania", name: "Mauritania", aliases: ["MRT"] },
    { value: "Mauritius", name: "Mauritius", aliases: ["MUS"] },
    { value: "Mexico", name: "Mexico", aliases: ["MEX"] },
    { value: "Micronesia", name: "Micronesia" },
    { value: "Moldova", name: "Moldova" },
    { value: "Monaco", name: "Monaco", aliases: ["MCO"] },
    { value: "Mongolia", name: "Mongolia", aliases: ["MNG"] },
    { value: "Montenegro", name: "Montenegro", aliases: ["MNE"] },
    { value: "Morocco", name: "Morocco", aliases: ["MAR"] },
    { value: "Mozambique", name: "Mozambique", aliases: ["MOZ"] },
    { value: "Myanmar", name: "Myanmar", aliases: ["MMR"] },
    { value: "Namibia", name: "Namibia", aliases: ["NAM"] },
    { value: "Nauru", name: "Nauru", aliases: ["NRU"] },
    { value: "Nepal", name: "Nepal", aliases: ["NPL"] },
    { value: "Netherlands", name: "Netherlands", aliases: ["NLD"] },
    { value: "New Zealand", name: "New Zealand", aliases: ["NZL"] },
    { value: "Nicaragua", name: "Nicaragua", aliases: ["NIC"] },
    { value: "Niger", name: "Niger", aliases: ["NER"] },
    { value: "Nigeria", name: "Nigeria", aliases: ["NGA"] },
    { value: "Norway", name: "Norway", aliases: ["NOR"] },
    { value: "Oman", name: "Oman", aliases: ["OMN"] },
    { value: "Pakistan", name: "Pakistan", aliases: ["PAK"] },
    { value: "Palau", name: "Palau", aliases: ["PLW"] },
    { value: "Panama", name: "Panama", aliases: ["PAN"] },
    { value: "Papua New Guinea", name: "Papua New Guinea", aliases: ["PNG"] },
    { value: "Paraguay", name: "Paraguay", aliases: ["PRY"] },
    { value: "Peru", name: "Peru", aliases: ["PER"] },
    { value: "Philippines", name: "Philippines", aliases: ["PHL"] },
    { value: "Poland", name: "Poland", aliases: ["POL"] },
    { value: "Portugal", name: "Portugal", aliases: ["PRT"] },
    { value: "Puerto Rico", name: "Puerto Rico", aliases: ["PRI"] },
    { value: "Qatar", name: "Qatar", aliases: ["QAT"] },
    { value: "Romania", name: "Romania", aliases: ["ROU"] },
    { value: "Russia", name: "Russia", aliases: ["RUS", "RU"] },
    { value: "Rwanda", name: "Rwanda", aliases: ["RWA"] },
    { value: "Saint Kitts and Nevis", name: "Saint Kitts and Nevis", aliases: ["KNA"] },
    { value: "Saint Lucia", name: "Saint Lucia", aliases: ["LCA"] },
    { value: "Saint Vincent and the Grenadines", name: "Saint Vincent and the Grenadines", aliases: ["VCT"] },
    { value: "Samoa", name: "Samoa", aliases: ["WSM"] },
    { value: "San Marino", name: "San Marino", aliases: ["SMR"] },
    { value: "Sao Tome and Principe", name: "Sao Tome and Principe", aliases: ["STP"] },
    { value: "Saudi Arabia", name: "Saudi Arabia", aliases: ["SAU"] },
    { value: "Senegal", name: "Senegal", aliases: ["SEN"] },
    { value: "Serbia and Montenegro", name: "Serbia and Montenegro" },
    { value: "Seychelles", name: "Seychelles", aliases: ["SYC"] },
    { value: "Sierra Leone", name: "Sierra Leone", aliases: ["SLE"] },
    { value: "Singapore", name: "Singapore", aliases: ["SGP"] },
    { value: "Slovakia", name: "Slovakia", aliases: ["SVK"] },
    { value: "Slovenia", name: "Slovenia", aliases: ["SVN"] },
    { value: "Solomon Islands", name: "Solomon Islands", aliases: ["SLB"] },
    { value: "Somalia", name: "Somalia", aliases: ["SOM"] },
    { value: "South Africa", name: "South Africa", aliases: ["ZAF"] },
    { value: "Spain", name: "Spain", aliases: ["ESP", "ES", "ESP"] },
    { value: "Sri Lanka", name: "Sri Lanka", aliases: ["LKA"] },
    { value: "Sudan", name: "Sudan", aliases: ["SDN"] },
    { value: "Suriname", name: "Suriname", aliases: ["SUR"] },
    { value: "Swaziland", name: "Swaziland", aliases: ["SWZ"] },
    { value: "Sweden", name: "Sweden", aliases: ["SWE", "SE"] },
    { value: "Switzerland", name: "Switzerland", aliases: ["CHE"] },
    { value: "Syria", name: "Syria" },
    { value: "Taiwan", name: "Taiwan" },
    { value: "Tajikistan", name: "Tajikistan", aliases: ["TJK"] },
    { value: "Tanzania", name: "Tanzania" },
    { value: "Thailand", name: "Thailand", aliases: ["THA"] },
    { value: "Togo", name: "Togo", aliases: ["TGO"] },
    { value: "Tonga", name: "Tonga", aliases: ["TON"] },
    { value: "Trinidad and Tobago", name: "Trinidad and Tobago", aliases: ["TTO"] },
    { value: "Tunisia", name: "Tunisia", aliases: ["TUN"] },
    { value: "Turkey", name: "Turkey", aliases: ["TUR"] },
    { value: "Turkmenistan", name: "Turkmenistan", aliases: ["TKM"] },
    { value: "Tuvalu", name: "Tuvalu", aliases: ["TUV"] },
    { value: "Uganda", name: "Uganda", aliases: ["UGA"] },
    { value: "Ukraine", name: "Ukraine", aliases: ["UKR"] },
    { value: "United Arab Emirates", name: "United Arab Emirates", aliases: ["ARE"] },
    { value: "United Kingdom", name: "United Kingdom", aliases: ["UK", "GBR", "GB"] },
    { value: "Uruguay", name: "Uruguay", aliases: ["URY"] },
    { value: "Uzbekistan", name: "Uzbekistan", aliases: ["UZB"] },
    { value: "Vanuatu", name: "Vanuatu", aliases: ["VUT"] },
    { value: "Vatican City", name: "Vatican City" },
    { value: "Venezuela", name: "Venezuela" },
    { value: "Vietnam", name: "Vietnam" },
    { value: "Yemen", name: "Yemen", aliases: ["YEM"] },
    { value: "Zambia", name: "Zambia", aliases: ["ZMB"] },
    { value: "Zimbabwe", name: "Zimbabwe", aliases: ["ZWE"] },
];
