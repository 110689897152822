import { inject, Injectable } from "@angular/core";

import { ApiService } from "./api.service";
import { ImageProcessorService } from "./capture/image-processor.service";

export type FilesToUploadType = string[] | Record<"front" | "back", string | null> | string;

@Injectable({
    providedIn: "root",
})
export class UploadService {
    private api = inject(ApiService);
    private imgProcessor = inject(ImageProcessorService);

    public async uploadDataUri(dataToUpload: FilesToUploadType): Promise<FilesToUploadType> {
        if (Array.isArray(dataToUpload)) {
            return Promise.all(
                dataToUpload.map(async (dataUri: string) => {
                    return this.uploadFile(dataUri);
                }),
            );
        } else if (typeof dataToUpload === "object") {
            // data should be for businessCard
            const front = dataToUpload.front ? await this.uploadFile(dataToUpload.front) : null;
            const back = dataToUpload.back ? await this.uploadFile(dataToUpload.back) : null;
            return { front, back };
        } else if (typeof dataToUpload === "string") {
            return this.uploadFile(dataToUpload);
        }
    }

    async uploadFile(data: string): Promise<string> {
        if (data.startsWith("http")) return data;

        const blob = this.imgProcessor.dataURItoBlob(data);
        //extracting extension from dataUri type
        const fileName = "file" + Date.now() + "." + data.split("/")[1].split(";")[0];
        return (await this.api.uploadFiles(blob, fileName).toPromise()).body!.files.files.url;
    }
}
