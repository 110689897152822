import { ScrollingModule } from "@angular/cdk/scrolling";
import { CommonModule, DatePipe } from "@angular/common";
import { HttpClient, HttpClientJsonpModule, HttpClientModule } from "@angular/common/http";
import { ErrorHandler, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { Router, RouteReuseStrategy } from "@angular/router";
import { Geolocation } from "@ionic-native/geolocation/ngx";
import { ScreenOrientation } from "@ionic-native/screen-orientation/ngx";
import { IonicModule, IonicRouteStrategy, Platform } from "@ionic/angular";
import { NgSelectModule } from "@ng-select/ng-select";
import { TranslateLoader, TranslateModule, TranslatePipe, TranslateService, TranslateStore } from "@ngx-translate/core";
import * as Sentry from "@sentry/angular-ivy";
import { TraceService } from "@sentry/angular-ivy";
import { AngularCropperjsModule } from "angular-cropperjs";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ComponentModule } from "./components/component.module";
import { FormViewModule } from "./components/form-view/form-view.module";
import { DirectivesModule } from "./directives/directives.module";
import { ActivationElementPageModule } from "./pages/activation-element/activation-element.module";
import { PipesModule } from "./pipes/pipes.module";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

@NgModule({
    declarations: [AppComponent],
    imports: [
        AngularCropperjsModule,
        FormsModule,
        BrowserModule,
        TranslateModule.forRoot({
            defaultLanguage: "en",
            loader: {
                provide: TranslateLoader,
                useFactory: function createTranslateLoader(http: HttpClient) {
                    return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
                },
                deps: [HttpClient],
            },
        }),
        IonicModule.forRoot({
            backButtonText: "",
            swipeBackEnabled: false,
            mode: "ios",
        }),
        AppRoutingModule,
        HttpClientModule,
        HttpClientJsonpModule,
        BrowserAnimationsModule,
        ComponentModule,
        NgSelectModule,
        PipesModule,
        DirectivesModule,
        CommonModule,
        IonicModule,
        FormViewModule,
        TranslateModule,
        ActivationElementPageModule,
        ScrollingModule,
    ],
    providers: [
        ScreenOrientation,
        HttpClient,
        TranslateService,
        TranslatePipe,
        TranslateStore,
        Platform,
        DatePipe,
        Geolocation,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: TraceService,
            deps: [Router],
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
