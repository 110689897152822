import { inject, Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ActionSheetController, AlertController, LoadingController, ToastController } from "@ionic/angular";
import { ActionSheetButton, AlertButton, AlertInput } from "@ionic/core";
import { TranslateService } from "@ngx-translate/core";

import { LAUNCHER_TYPES, PARAMS } from "../constants/ParamsNames";
import { ToastTheme } from "../constants/toast-theme";
import { OUTBOUND_MESSAGES } from "../model/messages";
import { Util } from "../util/util";

@Injectable({
    providedIn: "root",
})
export class PopupService {
    public alertCtrl = inject(AlertController);
    public toastCtrl = inject(ToastController);
    public actionCtrl = inject(ActionSheetController);
    private translate = inject(TranslateService);
    public loadingCtrl = inject(LoadingController);
    private activatedRoute = inject(ActivatedRoute);
    alert: HTMLIonAlertElement | null;
    popover: HTMLIonPopoverElement | null;
    private loading: HTMLIonLoadingElement | null;
    private toast: HTMLIonToastElement | null;
    private actionSheet: HTMLIonActionSheetElement | null;
    private isActive = { loading: false, toast: false, actionSheet: false, popover: false, alert: false };

    async showLoading(message: { text: string; params?: any }, theme = ""): Promise<HTMLIonLoadingElement> {
        this.isActive.loading = true;
        this.loading = await this.loadingCtrl.create({
            message: message.text ? this.translate.instant(message.text, message.params || {}) : "",
            cssClass: theme,
        });
        await this.loading.present();
        return this.loading;
    }

    async showAlert(
        title: string,
        message: { text: string; params?: any },
        buttons: AlertButton[],
        theme = "",
        inputs = [],
    ): Promise<HTMLIonAlertElement> {
        this.isActive.alert = true;
        if (this.alert) await this.alert.dismiss();
        this.alert = await this.alertCtrl.create({
            header: this.translate.instant(title),
            message: message.text ? this.translate.instant(message.text, message.params || {}) : "",
            buttons: this.translateButtons(buttons),
            inputs: this.translateInputs(inputs),
            backdropDismiss: false,
            cssClass: theme,
        });
        this.isActive.alert = false;
        await this.alert.present();
        return this.alert;
    }

    translateButtons<T extends AlertButton | ActionSheetButton>(buttons: T[]): T[] {
        return buttons.map((e) => ({
            ...e,
            text: e.text ? this.translate.instant(e.text) : "",
        }));
    }

    async showActionSheet(
        title: string,
        buttons: ActionSheetButton[],
        theme = "",
    ): Promise<HTMLIonActionSheetElement | void> {
        if (this.isActive.actionSheet) return;
        this.isActive.actionSheet = true;
        if (this.actionSheet) await this.actionSheet.dismiss();
        this.actionSheet = await this.actionCtrl.create({
            header: title ? this.translate.instant(title) : "",
            buttons: this.translateButtons(buttons),
            cssClass: theme,
        });
        this.isActive.actionSheet = false;
        await this.actionSheet.present();
        return this.actionSheet;
    }

    async showToast(
        message: { text: string; params?: any },
        position: "top" | "bottom" | "middle" = "top",
        theme = ToastTheme.Error,
        duration = 10000,
        cssClass = "",
    ): Promise<HTMLIonToastElement | void> {
        if (!message.text) return;
        const translatedMessage = message.text ? this.translate.instant(message.text, message.params || {}) : "";

        if (this.isEmbedded()) {
            this.postFormErrorMessage(translatedMessage);
            return;
        }

        this.toast = await this.toastCtrl.create({
            message: translatedMessage,
            duration: duration,
            position: position,
            color: theme,
            buttons: [{ side: "end", icon: "close" }],
            cssClass,
        });

        //  https://forum.ionicframework.com/t/ionic-version-6-angular-toast-now-holds-focus-preventing-inputs-from-being-used/218430/6
        this.toast.removeAttribute("tabindex");
        await this.toast.present();
        return this.toast;
    }

    async showPrompt(
        title: { text: string; params?: any },
        message: { text: string; params?: any },
        inputs: AlertInput[],
        buttons: AlertButton[],
        theme = "",
    ): Promise<HTMLIonAlertElement> {
        this.isActive.toast = true;
        if (this.alert) await this.alert.dismiss();
        const translatedMessage = message.text ? this.translate.instant(message.text, message.params || {}) : "";

        if (this.isEmbedded()) {
            this.postFormErrorMessage(translatedMessage);
            return;
        }
        this.alert = await this.alertCtrl.create({
            header: title.text ? this.translate.instant(title.text, title.params || {}) : "",
            message: translatedMessage,
            inputs: this.translateInputs(inputs),
            buttons: this.translateButtons(buttons),
            cssClass: theme,
        });
        await this.alert.present();
        return this.alert;
    }

    translateInputs(inputs: AlertInput[]): AlertInput[] {
        return inputs.map((input) => ({
            ...input,
            label: input.label ? this.translate.instant(input.label) : "",
            placeholder: input.placeholder ? this.translate.instant(input.placeholder) : "",
        }));
    }

    async dismiss(type: "alert" | "loading" | "toast" | "actionSheet" | "popover", data?: any): Promise<void> {
        if (this[type]) await this[type]?.dismiss(data);
        this[type] = null;
    }

    private isEmbedded(): boolean {
        // if it's embedded in event gen mobile or event gen web. emit a message and don't show the toast
        const launcher = this.activatedRoute.snapshot.queryParamMap.get(PARAMS.LAUNCHER);

        return launcher === LAUNCHER_TYPES.EVENT_GEN_MOBILE || launcher === LAUNCHER_TYPES.EVENT_GEN_WEB;
    }

    private postFormErrorMessage(translatedMessage: string): void {
        Util.customPostMessage(
            JSON.stringify({ type: OUTBOUND_MESSAGES.FORM_ERROR_MESSAGE, data: translatedMessage }),
            "*",
        );
    }
}
