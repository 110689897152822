import { inject, Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from "@angular/router";

import { PARAMS } from "../constants/ParamsNames";

// Goal: provide compatability for old urls
// https://staging-capture.captello.com/#/event/111/37261e6e41d3398ccb66fddeb4543084/submit
// to the new one
// http://localhost:8100/#/submission?f=111&s=1658826603963&e=37261e6e41d3398ccb66fddeb4543084&m=submit
@Injectable({ providedIn: "root" })
export class RedirectActivationToNewUrl implements CanActivate {
    private router = inject(Router);

    canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree {
        const [activationId, webToken, mode] = route.url.map((x) => x.path);
        const oldQueries = route.queryParams;

        if (activationId && webToken && mode) {
            const mod = mode.split("-")[0]; // "preview" "submit" or "submit-station:123
            const isMatched = mode.match(/\d+/g);
            const stationId = isMatched ? isMatched[0] : null;

            const queryParams = {
                [PARAMS.ACTIVATION_ID]: activationId,
                // [PARAMS.SUBMISSION_ID]: "1658826603963",
                [PARAMS.EVENT_WEB_ACCESS_TOKEN]: webToken,
                [PARAMS.MODE]: mod,
                ...oldQueries,
            };
            if (stationId) {
                queryParams[PARAMS.STATION_ID] = stationId;
            }
            return this.router.createUrlTree(["capture/activation"], {
                queryParams,
            });
        } else {
            return false;
        }
    }
}
