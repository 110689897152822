interface Invitation {
    invitation_code: string;
    is_universal: boolean;
    created_at: number;
}
export class UserWithAccessToForm {
    userid: number = 0;
    firstname: string = "";
    lastname: string = "";
    fullName: string = "";
    username: string = "";
    email: string = "";
    title: string = "";
    phone_number: string = "";
    file_user_profile_picture: string = "";
    has_device: boolean = false;
    has_device_connected: boolean = false;
    invitation: Invitation | null = null;
    public static getInstance(data: any): UserWithAccessToForm {
        const formUser = new UserWithAccessToForm();
        Object.keys(formUser).forEach((key) => {
            try {
                if (["firstname", "lastname"].includes(key)) {
                    formUser[key] = data[key];
                    return;
                }
                formUser[key] = typeof data[key] == "string" ? JSON.parse(data[key]) : data[key];
            } catch (error) {
                formUser[key] = data[key];
            }
        });
        formUser.fullName = formUser.firstname + " " + formUser.lastname;
        return formUser;
    }
    // backend will stop supporting the oldUserSchema in the future
    public static getInstanceFromOldUserModel(oldModel: {
        id: number;
        first_name: string;
        last_name: string;
        user_name: string;
        email: string;
        title: string;
        phone_number: string;
        last_login: string;
        number_of_logins: number;
        is_admin: 0 | 1;
        is_active: 0 | 1;
        is_available: 0 | 1;
        user_profile_picture: string;
    }): UserWithAccessToForm {
        const user = new UserWithAccessToForm();
        user.userid = oldModel.id;
        user.firstname = oldModel.first_name || "";
        user.lastname = oldModel.last_name || "";
        user.fullName = oldModel.first_name + " " + oldModel.last_name;
        user.email = oldModel.email;
        user.title = oldModel.title;
        user.phone_number = oldModel.phone_number;
        user.file_user_profile_picture = oldModel.user_profile_picture || "";
        user.has_device = oldModel["has_device"] || false;
        // https://leadliaison.slack.com/archives/D02718G9STD/p1658398368937899
        user.has_device_connected = oldModel["has_device_connected"] || oldModel.is_available;
        user.invitation = null;
        return user;
    }
}
