import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import Compressor from "compressorjs";
import path from "path";
import { isEmail } from "validator";

import { ElementId, ElementOrSubElementId, SubElementId } from "../model";

@Injectable({ providedIn: "root" })
export class Util {
    public static elementScroll(id: string, options: ScrollIntoViewOptions): void {
        if (id) {
            const el = document.getElementById(id);
            if (el) {
                el.scrollIntoView(options);
            }
        }
    }

    public static replaceStringTokens(str: string, obj: Record<any, any>): string {
        return str.replace(/{([^{}]*)}/g, (a, b) => {
            const r = obj[b];
            return r ? r : a;
        });
    }

    public static getFileExtension(fileName: string): string {
        return path.extname(fileName).replace(/\./g, "");
    }

    public static isImageFormat(ext: string): boolean {
        return ["jpg", "png", "gif", "jpeg", "svg"].includes(ext.toLowerCase());
    }

    public static hexToRgb(hex: string): string {
        // Remove the '#' character if it exists
        hex = hex.replace("#", "");

        // Check if the remaining string is a valid hex code
        if (!/^[0-9a-fA-F]{6}$/.test(hex)) {
            // Return black if the code is invalid
            return "0,0,0";
        }

        // Convert the hex values to decimal
        const r = parseInt(hex.substring(0, 2), 16);
        const g = parseInt(hex.substring(2, 4), 16);
        const b = parseInt(hex.substring(4, 6), 16);

        // Return the RGB color as a string
        return `${r},${g},${b}`;
    }

    public static convertYearToDays(yrs: number): number {
        return Math.floor(yrs * 365.25);
    }

    public static parseJson(data: any): any {
        if (typeof data != "string") return data;
        try {
            return JSON.parse(data);
        } catch (error) {
            return data;
        }
    }

    public static convertTo24Hour(time: string): string {
        time = time.toLowerCase();
        const hoursBeforeParsing = time.substr(0, 2);
        const hours = parseInt(hoursBeforeParsing);
        if (time.indexOf("am") != -1 && hours == 12) {
            time = time.replace("12", "00");
        }
        if (time.indexOf("pm") != -1 && hours < 12) {
            time = time.replace(hoursBeforeParsing, String(hours + 12));
        }
        return time.replace(/(am|pm)/, "").trim();
    }

    public static safeParse(data: any): any {
        try {
            return JSON.parse(data);
        } catch (e) {
            return "";
        }
    }

    public static blobToBase64(blob: any): Promise<string | ArrayBuffer> {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
        });
    }

    public static getQueryStrings(snapshot: ActivatedRouteSnapshot, startWith: string): Record<string, string> {
        const params = {};
        for (const key in snapshot.queryParams) {
            if (key.startsWith(startWith)) {
                params[key] = snapshot.queryParams[key];
            }
        }
        return params;
    }

    public static compressAsync(file: File | Blob, quality: number): Promise<File | Blob> {
        return new Promise((resolve, reject) => {
            new Compressor(file, {
                quality: quality,
                success(result) {
                    resolve(result);
                },
                error(err) {
                    reject(err.message);
                },
            });
        });
    }

    static isArrayOfString(obj: any): obj is string[] {
        return Array.isArray(obj) && obj.every((el) => typeof el === "string");
    }

    public static getFullName(firstName: string | undefined, lastName: string | undefined): string {
        return (firstName ? firstName : "") + (lastName ? " " + lastName : "");
    }

    public static validateEmail(email: string): boolean {
        return isEmail(email);
    }

    public static customPostMessage(message: string, origin: string = "*"): void {
        /*
         the mobile app will create a channel called eventGen,
         don't add * to the postMessage when sending to the mobile channel
         because it won't send it
         */

        // @ts-ignore
        if (typeof eventGen !== "undefined") {
            // @ts-ignore
            eventGen.postMessage(message);
            return;
        }

        window.parent.postMessage(message, origin);
    }

    /**
     * If the id is already a parent id, it will return it
     * @example
     *   // For ID: "element_1_sub_1_2", it will return "element_1_sub_1"
     *   // For ID: "element_2_5", it will return "element_2"
     *   // For ID: "element_2", it will return "element_2"
     */
    public static getParentId(id: ElementOrSubElementId): ElementId {
        if (!this.isSubElementId(id)) return id;
        return id.split("_").slice(0, -1).join("_") as ElementId;
    }

    /**
     * @example
     *   // For ID: "element_1", it will return true
     *   // For ID: "element_1_sub_1", it will return true
     */
    public static isParentId(id: string): id is ElementId {
        return /^element_\d+(?:_[a-zA-Z]+_\d+)?$/.test(id);
    }

    /**
     * @example
     *   // For ID: "element_1_2", it will return true
     *   // For ID: "element_1_sub_1_2", it will return true
     */
    public static isSubElementId(id: string): id is SubElementId {
        return /^element_\d+_(\w+_\d+_)?\d+$/.test(id);
    }

    public static appendEmailToDestinationUrl(sourceUrl: string, destinationUrl: string): string {
        const URL_1 = new URL(sourceUrl);
        const URL_2 = new URL(destinationUrl);
        if (URL_1.hostname === URL_2.hostname) {
            if (URL_1.hostname.endsWith("capture.captello.com")) {
                const emailParamValue = URL_1.searchParams.get("email") || URL_1.searchParams.get("eml");
                if (emailParamValue !== null && emailParamValue !== "") {
                    const paramKey = URL_1.searchParams.get("email") ? "email" : "eml";
                    URL_2.searchParams.append(paramKey, emailParamValue);
                }
            }
        }
        return URL_2.toString();
    }

    public static processUrlThenRedirect(url: string): void {
        const destinationUrl = this.appendEmailToDestinationUrl(window.location.href, url);
        window.open(destinationUrl, "_self");
    }
}
