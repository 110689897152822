export interface IDocument {
    id: number;
    name: string;
    selected?: boolean;
    is_default_selected: boolean;
    updated_at: string;
    preview_urls: { small: string; medium: string; large: string; normal: string };
    vanity_url: string;
}

export enum DocumentsViewMode {
    GRID = "grid",
    LIST = "list",
}
