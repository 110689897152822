import { inject, Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate } from "@angular/router";

import { PARAMS } from "../constants/ParamsNames";
import { ApiService } from "../services/api.service";

@Injectable({ providedIn: "root" })
export class SetToken implements CanActivate {
    private apiService = inject(ApiService);

    canActivate(route: ActivatedRouteSnapshot): boolean {
        const event_web_access_token = route.queryParamMap.get(PARAMS.EVENT_WEB_ACCESS_TOKEN);

        if (event_web_access_token) {
            this.apiService.setToken(event_web_access_token);
            return true;
        } else {
            return false;
        }
    }
}
