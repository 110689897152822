import { HttpClient, HttpEventType, HttpHeaders } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { Params } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { EMPTY, Observable, of, retry, throwError, timer } from "rxjs";
import { catchError, expand, map, mergeMap, pluck, tap, timeout } from "rxjs/operators";

import { environment } from "../../environments/environment";
import { Activation, ActivationSubmission, BadgeResponse, ElementId, Form, FormSubmission } from "../model";
import { Severity } from "../model/SentryTypes";
import { UserWithAccessToForm } from "../model/admin/UserWithAccessToForm";
import { Meeting } from "../model/meeting";
import { ProspectTrackingInfo } from "../model/prospect-tracking-info";
import { FileUploadResponse } from "../model/protocol/file-upload-response";
import { FormSubmitResponse } from "../model/protocol/form-submit-response";
import { RecordsResponse } from "../model/protocol/records-response";
import { BaseResponse } from "../model/protocol/response";
import { draftSubmissionResponse, SubmissionResponse } from "../model/protocol/submission-response";
import { SubmitFormResponse } from "../model/submitFormResponse";

import { AnalyticsService } from "./analytics.service";
import { PARAMS } from "../constants/ParamsNames";

type Parameters = Record<string, string | number | boolean>;

@Injectable({
    providedIn: "root",
})
export class ApiService {
    private http = inject(HttpClient);
    private translate = inject(TranslateService);
    // private token: string = "7959b08cc7c1afa8cdf41807e23eb000"; //staging
    // private token: string = "770739ec7c009b77446febd964085f6a";  //demo
    private token: string = ""; //demo

    getProspectData(url: string): Observable<ProspectTrackingInfo> {
        return this.http.jsonp<ProspectTrackingInfo>(url, "callback");
    }

    setToken(token: string): void {
        this.token = token;
    }

    public getFormByCurrentToken(formType: "device" | "template" = "device", qs: Params): Observable<Form> {
        const params: Parameters = {
            form_type: formType,
            qs: JSON.stringify(qs),
            localization: qs[PARAMS.LANGUAGE] || "en",
        };
        return this.get<RecordsResponse<Form>>("/forms.json", params).pipe(
            map((res) => {
                return Form.getInstance(res.records[0]);
            }),
        );
    }

    // submission
    public getSubmission(formId: string, submissionToken: string): Observable<SubmissionResponse[]> {
        const params: Parameters = {
            form_id: formId,
            submission_token: submissionToken,
            form_type: "device",
        };
        return this.get<RecordsResponse<SubmissionResponse>>("/forms/submissions.json", params).pipe(
            map((res) => {
                return res.records;
            }),
        );
    }

    // get form owners
    public getFormOwners(
        formId: Form["form_id"],
        params: Parameters,
    ): Observable<RecordsResponse<UserWithAccessToForm>> {
        return this.get<RecordsResponse<UserWithAccessToForm>>(`/forms/${formId}/assign-owner-users.json`, {
            limit: 20,
            ...params,
        }).pipe(
            tap((res) =>
                res.records.map((record) => (record.fullName = [record.firstname, record.lastname].join(" "))),
            ),
        );
    }

    // ocr
    // admin
    public getUsers(params?: Parameters): Observable<RecordsResponse<UserWithAccessToForm>> {
        return this.get<RecordsResponse<any>>("/users.json", {
            sort_by: "name",
            limit: 20,
            sort_order: "ASC",
            ...params,
        }).pipe(
            map((res) => {
                let records;
                //backend doesn't return full_name and sometimes the request success without having a records property
                if (res.records) {
                    records = res.records.map((oldUser) => {
                        return UserWithAccessToForm.getInstanceFromOldUserModel(oldUser);
                    });
                }
                return {
                    ...res,
                    ...(records && { records: records }),
                };
            }),
        );
    }

    public getActivationById(id: string, params: Parameters = {}): Observable<Activation> {
        return this.get(`/activations/${id}.json`, params).pipe(
            map((res: { data }) => {
                return Activation.parseActivation(res.data, Form.getInstance(res.data.event));
            }),
        );
    }

    public fetchBadgeData(
        barcodeId: string,
        providerId: string,
        isRapidScan: number = 0,
        formId?: Form["form_id"],
    ): Observable<BadgeResponse> {
        const params: { is_rapid_scan: number; provider_id: string; barcode_id: string; form_id?: number } = {
            barcode_id: barcodeId,
            provider_id: providerId,
            is_rapid_scan: isRapidScan,
        };
        if (formId) params.form_id = formId;
        return this.get<BadgeResponse>("/barcode/scan.json", params).pipe(
            map((badgeData) => ({
                ...badgeData,
                info: badgeData.info.map((entry) => ({ ...entry, value: entry.value === null ? "" : entry.value })),
            })),
        );
    }

    public getFormElementMeetings(formId: Form["form_id"], elementId: ElementId): Observable<Meeting[]> {
        return this.getAll<Meeting>(`/forms/${formId}/meeting-elements/${elementId}.json`, {
            localization: this.translate.getDefaultLang(),
        }).pipe(map((resp) => resp.records));
    }

    public getFormMeetings(formId: Form["form_id"]): Observable<Meeting[]> {
        return this.getAll<Meeting>(`/forms/${formId}/meeting-types.json`, {
            localization: this.translate.getDefaultLang(),
        }).pipe(map((resp) => resp.records));
    }

    public getActivationMeetings(actId: Activation["id"]): Observable<Meeting[]> {
        return this.getAll<Meeting>(`/activations/${actId}/meeting-types.json`, {
            localization: this.translate.getDefaultLang(),
        }).pipe(pluck("records"));
    }

    public submitActivation(data: ActivationSubmission): Observable<FormSubmitResponse> {
        return this.post("/activations/submit.json", data);
    }

    /**
     *
     * @returns Observable
     */
    public submitForm(submission: FormSubmission, form: Form): Observable<SubmitFormResponse> {
        return this.post("/forms/submit.json", submission).pipe(
            map((resp: FormSubmitResponse) => {
                // accept and merge duplicate actions
                if (resp.ll_update_tracking_info_url) {
                    this.http.jsonp(resp.ll_update_tracking_info_url, "callback").subscribe();
                }
                if (resp.status == 200) {
                    let newSubmission: FormSubmission | undefined;
                    if (resp.submission) {
                        newSubmission = FormSubmission.getInstance(resp.submission);
                        newSubmission.getMappedFromResponse(resp.submission, form);
                    }
                    return {
                        activity_id: resp.activity_id,
                        prospect_id: resp.prospect_id,
                        hold_request_id: resp.hold_request_id,
                        message: resp.message,
                        response_status: resp.status,
                        form_id: submission.form_id,
                        is_new_submission: resp.is_new_submission,
                        submission: newSubmission || submission,
                        ll_update_tracking_info_url: resp.ll_update_tracking_info_url,
                        redirect_url: resp.redirect_url,
                        submission_action_redirect_url: resp.submission_action_redirect_url,
                        submission_action_type: resp.submission_action_type,
                        submission_action_message: resp.submission_action_message,
                        additional_actions: resp.additional_actions,
                    };
                }

                // reject and edit duplicate action
                return {
                    activity_id: resp.activity_id,
                    prospect_id: resp.prospect_id,
                    hold_request_id: null,
                    message: resp.message,
                    response_status: resp.status,
                    form_id: submission.form_id,
                    duplicate_action: resp.duplicate_action,
                    is_new_submission: resp.is_new_submission,
                    submission: submission,
                    show_error_message: resp.show_error_message,
                    ll_update_tracking_info_url: resp.ll_update_tracking_info_url,
                };
            }),
            catchError((error) => {
                AnalyticsService.captureException({
                    error,
                    level: Severity.Warning,
                    tags: {
                        formId: form.form_id,
                        submissionId: submission.id,
                    },
                });
                return throwError(error);
            }),
        );
    }

    public uploadFiles(
        blobData: Blob,
        fileName: string,
    ): Observable<{ type: HttpEventType; loaded?: number; total?: number; body?: FileUploadResponse }> {
        const formData = new FormData();
        formData.append("files", blobData, fileName);
        return this.post("/drive/new-upload.json", formData, {
            reportProgress: true,
            observe: "events",
            responseType: "json",
        });
    }

    private getAll<T>(relativeUrl: string, options: Parameters): Observable<RecordsResponse<T>> {
        let offset = +options.offset || 0;
        return this.get<RecordsResponse<T>>(relativeUrl, { ...options }).pipe(
            expand((res) => {
                res.records = res.records || [];
                if (res.count + offset < res.total_count) {
                    offset += res.count;
                    return this.get<RecordsResponse<T>>(relativeUrl, { ...options, offset });
                } else {
                    return EMPTY;
                }
            }),
        );
    }

    public get<T>(relativeUrl: string, params: Parameters = {}): Observable<T> {
        let search = "?event_web_access_token=" + encodeURIComponent(this.token);
        for (const field in params) {
            search += "&" + encodeURIComponent(field) + "=" + encodeURIComponent(params[field]);
        }
        const url = environment.API_URL + relativeUrl + search;

        return this.http.get<T>(url, { headers: this.getHeaders() }).pipe(
            retry({
                count: 3,
                delay: genericRetryStrategy({
                    scalingDuration: 5000,
                    excludedStatusCodes: [400, 404, 401, 405],
                }),
            }),
            timeout(30000),
            catchError((error) => {
                AnalyticsService.captureException({
                    error,
                    level: Severity.Error,
                    data: {
                        relativeUrl,
                        url,
                        params,
                    },
                });
                return throwError(error);
            }),
        );
    }

    private post<T>(relativeUrl: string, data: unknown, options: Parameters = {}): Observable<T> {
        const url = `${environment.API_URL}${relativeUrl}?event_web_access_token=${this.token}`;

        return this.http.post<T>(url, data, { headers: this.getHeaders(), ...options }).pipe(
            retry({
                count: 3,
                delay: genericRetryStrategy({
                    scalingDuration: 10000,
                    excludedStatusCodes: [400, 404, 401, 405],
                }),
            }),
            timeout(300000),
            catchError((error) => {
                AnalyticsService.captureException({
                    error,
                    level: Severity.Error,
                    data: {
                        relativeUrl,
                        url,
                        data,
                        options,
                    },
                });
                return throwError(error);
            }),
        );
    }

    private getHeaders(): HttpHeaders {
        const headers = new HttpHeaders();
        headers.append("Content-Type", "application/json");
        return headers;
    }

    public getDraft(formId: Form["id"], submissionToken: string): Observable<Record<string, string>> {
        return this.get<{ records: draftSubmissionResponse }>(
            `/forms/${formId}/drafted-submissions/${submissionToken}.json`,
        ).pipe(
            mergeMap((resp) => {
                if (!resp.records?.data) return throwError("empty draft");

                return of(resp.records.data);
            }),
        );
    }

    sendNotifyOtherAction(formId: Form["form_id"], formFillActionId: number): Observable<BaseResponse> {
        return this.post(`/forms/${formId}/apply-form-fill-actions.json`, {
            form_fill_action_id: formFillActionId,
        });
    }
}

export const genericRetryStrategy =
    ({
        scalingDuration = 1000,
        excludedStatusCodes = [],
    }: {
        scalingDuration?: number;
        excludedStatusCodes?: number[];
    } = {}) =>
    (error: any, retryCount: number) => {
        if (excludedStatusCodes.find((err) => err === error.status)) {
            return throwError(() => error);
        }
        // retry after 1s, 2s, etc...
        console.log(`Attempt ${retryCount}: retrying in ${retryCount * scalingDuration}ms`);
        return timer(retryCount * scalingDuration);
    };
