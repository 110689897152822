import { inject, Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Pipe({
    name: "safeHtml",
})
export class SafeHtmlPipe implements PipeTransform {
    private sanitized = inject(DomSanitizer);

    transform(value: any): SafeResourceUrl | string {
        if (typeof value !== "string") {
            return "";
        }
        return this.sanitized.bypassSecurityTrustHtml(value);
    }
}
