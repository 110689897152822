import { HexColor } from "../util/typeAliases";

import { FormSubmission } from "./form-submission";
import { WebviewSuccessActionsTypes } from "./mobile-success-actions";

export interface SubmitFormResponse {
    activity_id: number;
    prospect_id: number;
    message: string;
    hold_request_id: number | null;
    response_status: 200 | 400 | 500;
    form_id: number;
    duplicate_action?: string;
    submission: FormSubmission;
    is_new_submission: boolean;
    show_error_message?: string;
    redirect_url?: string;

    // for quiz + survey forms
    submission_action_redirect_url?: string;
    submission_action_type?: WebviewSuccessActionsTypes;
    submission_action_message?: string;
    additional_actions?: SubmitResponseActionInfo[];
}

export enum SubmitResponseAction {
    SHOW_START_OVER_BUTTON = "show_start_over_button",
}

export type SubmitResponseActionInfo = {
    action: SubmitResponseAction.SHOW_START_OVER_BUTTON;
    info: {
        text: string;
        color: HexColor;
        background_color: HexColor;
    };
};
