import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, ResolveFn } from "@angular/router";
import { of } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { PARAMS } from "../constants/ParamsNames";
import { SubmissionResponse } from "../model/protocol/submission-response";
import { ApiService } from "../services/api.service";

/**
 * for normal submissions >> submissionData = SubmissionResponse
 * for drafted submissions >> submissionData = Record<string,string> (as backend only save it as we send)
 * */

export const SubmissionsResolver: ResolveFn<SubmissionResponse | Record<string, string>> = (
    route: ActivatedRouteSnapshot,
) => {
    const apiService = inject(ApiService);
    const formId = route.queryParamMap.get(PARAMS.FORM_ID);

    const submissionType = route.queryParamMap.get(PARAMS.SUBMISSION_TYPE);

    const submissionToken = route.queryParamMap.get(PARAMS.SUBMISSION_TOKEN);

    if (!submissionToken) return of(null);

    if (submissionType === SubmissionType.DRAFTED) {
        return apiService.getDraft(formId, submissionToken).pipe(
            catchError(() => {
                return of(null);
            }),
        );
    }
    return apiService.getSubmission(formId, submissionToken).pipe(
        map((sub) => {
            return sub[0];
        }),
        catchError(() => {
            return of(null);
        }),
    );
};

enum SubmissionType {
    NORMAL = "normal",
    DRAFTED = "drafted",
}
