import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, ResolveFn } from "@angular/router";
import { ModalController } from "@ionic/angular";
import { from, of } from "rxjs";
import { catchError } from "rxjs/operators";

import { PARAMS } from "../constants/ParamsNames";
import { Activation } from "../model";
import { ActivationErrorResponse } from "../model/protocol/response";
import { HtmlMessagePage } from "../pages/html-message/html-message.page";
import { ApiService } from "../services/api.service";
import { Util } from "../util/util";

export const ActivationResolver: ResolveFn<Activation | void> = (snapshot: ActivatedRouteSnapshot) => {
    const api = inject(ApiService);

    const act_id = snapshot.queryParamMap.get(PARAMS.ACTIVATION_ID);
    return api.getActivationById(act_id, getActParams(snapshot)).pipe(
        catchError((response: ActivationErrorResponse) => {
            const showError = response?.error?.show_error_message && response?.error?.message;
            return showError ? from(showErrorPage(response.error.message)) : of(null);
        }),
    );
};

function getActParams(snapshot: ActivatedRouteSnapshot): { [p: string]: string; qs: string } {
    const qs = JSON.stringify(snapshot.queryParams);
    return { ...Util.getQueryStrings(snapshot, "act_"), qs, localization: snapshot.queryParamMap.get(PARAMS.LANGUAGE) };
}

async function showErrorPage(message: string): Promise<void> {
    const modalController = inject(ModalController);

    const modal = await modalController.create({
        component: HtmlMessagePage,
        componentProps: {
            message: message,
            color: "",
        },
        swipeToClose: false,
        cssClass: "full-screen",
        animated: false,
        presentingElement: document.getElementById("main-content"),
    });
    await modal.present();
}
