<ion-header>
    <ion-toolbar>
        <ion-title
            >{{ (disableStationSelection ? "stations-view.visited-stations" : "stations-view.title") | translate }}
        </ion-title>
        @if (showCancel) {
            <ion-buttons slot="start" class="text-center">
                <ion-button fill="full" (click)="onCancel()"> {{ "general.cancel" | translate }}</ion-button>
            </ion-buttons>
        }
    </ion-toolbar>
    @if (!disableStationSelection) {
        <ion-toolbar>
            <ion-searchbar
                (ionInput)="getItems($event)"
                [placeholder]="'general.search' | translate"
                [enterkeyhint]="'search'"
                [showCancelButton]="'focus'"
                (ionCancel)="getItems($event)"
                (ionClear)="getItems($event)"
            ></ion-searchbar>
        </ion-toolbar>
    }
</ion-header>
<ion-content>
    <ion-list>
        @if (disableStationSelection) {
            <ion-item-divider></ion-item-divider>
            <ion-item lines="inset">
                <ion-label>
                    <ion-text color="primary"> {{ "stations-view.initial-station" | translate }}</ion-text>
                    <div>
                        {{ selectedStation ? selectedStation.name : ("stations-view.no-initial-station" | translate) }}
                    </div>
                </ion-label>
            </ion-item>
            <ion-item-divider></ion-item-divider>
            @if (visitedStations?.length) {
                @for (station of visitedStations | sortBy: "order"; track station) {
                    <ion-item lines="inset">
                        <ion-label>
                            <ion-text color="dark" class="station-assign-datetime"
                                >{{ station.assign_datetime | date: "MMM d, y - h:mm a" }}
                            </ion-text>
                            <div>{{ station.name }}</div>
                        </ion-label>
                    </ion-item>
                }
            }
        }
        @if (!disableStationSelection) {
            <ion-radio-group (ionChange)="onStationChoose($event)">
                @if (visitedStations?.length) {
                    <ion-item-divider> {{ "stations-view.stations" | translate }}</ion-item-divider>
                }
                @for (station of filteredStations | sortBy: "order"; track station) {
                    <ion-item lines="inset">
                        <ion-label>{{ station.name }}</ion-label>
                        <ion-radio [value]="station"></ion-radio>
                    </ion-item>
                }
            </ion-radio-group>
        }
    </ion-list>
</ion-content>
@if (!isSingleTap && !disableStationSelection) {
    <ion-footer>
        <ion-toolbar color="white">
            <ion-button fill="clear" expand="full" [disabled]="!selectedStation" (click)="done()">
                {{ "general.select" | translate }}
            </ion-button>
        </ion-toolbar>
    </ion-footer>
}
@if (disableStationSelection) {
    <ion-footer>
        <ion-toolbar color="white" style="padding: 16px">
            <ion-button fill="solid" expand="block" (click)="onCancel()"> {{ "general.done" | translate }}</ion-button>
        </ion-toolbar>
    </ion-footer>
}
