import { inject, Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

import { PARAMS } from "../constants/ParamsNames";
import { SUPPORTED_LANGUAGES } from "../constants/constants";

@Injectable({ providedIn: "root" })
export class SetLanguage {
    private translate = inject(TranslateService);

    canActivate(route: ActivatedRouteSnapshot): boolean {
        const lang = route.queryParamMap.get(PARAMS.LANGUAGE);

        const toBeUsed = SUPPORTED_LANGUAGES.includes(lang) ? lang : "en";
        this.translate.use(toBeUsed);
        document.documentElement.dir = lang == "ar" ? "rtl" : "ltr";

        return true;
    }
}
