import { Util } from "../util/util";

import { Verification } from "./verification";

export class AgeVerification extends Verification {
    age: number = 0;
    days: number = 0;
    first_name: string = "";
    days_until_verified_age: number = 0;
    days_over_verified_age: number = 0;

    is_enable_age_verification: boolean = false;

    is_enable_under_age_verification: boolean = false;
    is_enable_over_age_verification: boolean = false;
    is_enable_age_range_verification: boolean = false;

    verified_under_age: number = 0;
    verified_over_age: number = 0;
    start_verified_age: number = 0;
    end_verified_age: number = 0;

    under_age_verification_message: string = "";
    over_age_verification_message: string = "";
    age_range_verification_message: string = "";
    under_age_verification_title: string = "";
    over_age_verification_title: string = "";
    age_range_verification_title: string = "";

    is_show_option_to_proceed: boolean = false;

    public getAlertMsg(): { title: string; msg: string } | null {
        const data = this.getAlertObject();
        if (data) data.msg = Util.replaceStringTokens(data.msg, this);
        return data;
    }

    public setVerificationAttrs(dob: Date, firstName?: string): void {
        this.first_name = firstName || "";
        this.age = dob.getTime();
        const diff = Date.now() - this.age;
        this.days = Math.floor(diff / (1000 * 60 * 60 * 24)); // convert to days
        this.age = Math.floor(this.days / 365.25);
        this.verification_attr = this.age; // convert to years
        this.end_verified = this.end_verified_age;
        this.start_verified = this.start_verified_age;
        this.verified_over = this.verified_over_age;
        this.verified_under = this.verified_under_age;
        this.days_until_verified_age = Math.abs(Util.convertYearToDays(this.verified_under) - this.days);
        this.days_over_verified_age = Math.abs(this.days - Util.convertYearToDays(this.verified_over));
        this.is_enable_verification = this.is_enable_age_verification;
        this.is_enable_over_verification = this.is_enable_over_age_verification;
        this.is_enable_under_verification = this.is_enable_under_age_verification;
        this.is_enable_range_verification = this.is_enable_age_range_verification;
        this.over_verification_message = this.over_age_verification_message;
        this.under_verification_message = this.under_age_verification_message;
        this.range_verification_message = this.age_range_verification_message;
        // this.is_show_option_to_proceed = this.is_show_option_to_proceed;
        this.over_title = this.over_age_verification_title;
        this.under_title = this.under_age_verification_title;
        this.within_title = this.age_range_verification_title;
    }
}
