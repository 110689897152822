import { inject, Injectable } from "@angular/core";
import { ModalController } from "@ionic/angular";
import _shuffle from "lodash-es/shuffle";

import { ActivationStyle, EventStyle } from "../model";
import { InteractionsDetector } from "../util/interactions-detector";

@Injectable({ providedIn: "root" })
export class ScreenSaverService {
    private interactionsDetector = new InteractionsDetector();
    private _initialized: boolean = false;
    get initialized(): boolean {
        return this._initialized;
    }

    private shouldInit: boolean;

    public modalCtrl = inject(ModalController);

    init(screensaverData: EventStyle | ActivationStyle): void {
        this.shouldInit = !!(
            screensaverData.is_enable_screensaver && screensaverData.screensaver_media_items.length > 0
        );
        if (this.shouldInit) {
            this.interactionsDetector.setCallback(() => {
                if (this._initialized) return;
                this.showScreenSaver(screensaverData);
                this._initialized = true;
            });

            const rotationPeriod =
                "screensaver_rotation_period" in screensaverData
                    ? screensaverData.screensaver_rotation_period
                    : screensaverData.rotation_period;

            this.interactionsDetector.setDelay(rotationPeriod);
            this.interactionsDetector.ListenForInteraction();
        }
    }

    private handleScreenSaverRandomize(data: EventStyle | ActivationStyle): void {
        if (data.is_randomize) {
            data.screensaver_media_items = _shuffle(data.screensaver_media_items);
        }
    }

    private async showScreenSaver(data: EventStyle | ActivationStyle) {
        import("../pages/screen-saver/screen-saver.page").then(async (m) => {
            this.handleScreenSaverRandomize(data);
            const _modal = await this.modalCtrl.create({
                component: m.ScreenSaverPage,
                componentProps: { eventStyle: data },
                cssClass: "screen-saver-page-full-screen",
            });
            await _modal.present();
            await _modal.onDidDismiss();
            this.stopScreenSaver();
            this.interactionsDetector.ListenForInteraction();
        });
    }

    public stopScreenSaver(): void {
        this.interactionsDetector.stopListenForInteraction();
        this._initialized = false;
    }

    public startScreenSaver(): void {
        if (!this._initialized && this.shouldInit) this.interactionsDetector.ListenForInteraction();
    }
}
